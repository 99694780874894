import React, { Fragment } from "react";
import Layout from "../../layout/Layout";
import colors from "../../style/colors";
import { Box, Table, TableContainer, TableHead, TableBody, Typography, TableRow, TableCell, TableFooter, Pagination } from "@mui/material";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { userRows } from "../../utils/DummyData";
import TableFilter from "../../components/Filters/TableFilter";
import PrimaryTable from "../../components/Tables/PrimaryTable";
import OrdersServices from "../../apis/Orders/OrdersServices";
import { ErrorToaster } from "../../components/Toaster/Toaster";
import { useEffect } from "react";
import CategoryServices from "../../apis/Category/CategoryServices";
import { Assessment } from "@mui/icons-material";

//* Data Table Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  "& .MuiTableCell-head": {
    fontWeight: 600,
    fontSize: "0.875rem",
    minHeight: "16px",
    padding: "14px",
    color: `rgb(99, 115, 129)`,
  },
}));

const StyledTableBody = styled(TableBody)(() => ({
  "& .MuiTableCell-body": {
    fontWeight: 400,
    fontSize: "0.875rem",
    minHeight: "16px",
    lineHeight: "16px",
    color: `${colors.secondary}`,
    boxSizing: "none",
  },
}));

//* Logic for Pagination
function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}
//* Table Header Array
const tableHeader = [
  "Order No",
  "Order By User",
  // "Customer Name",
  "Order Date",
  "Delivery Date",
  "Total",
  "Paid Amount",
  "Balance Amount",
  "Status",
];

const Report = () => {
  // *For Pagination
  const [totalCount, setTotalCount] = useState(10);
  const [pageLimit, setPageLimit] = useState(10);
  // const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [balance, setBalance] = useState("");
  const [paid, setPaid] = useState("");
  const [footerTotal, setFooterTotal] = useState("");

  // let [page, setPage] = useState(1);
  // const PER_PAGE = 10;
  // const count = Math.ceil(userRows.length / PER_PAGE);
  // const _DATA = usePagination(userRows, PER_PAGE);

  //* API FOR GET ALL CATEGORIES
  const getCategories = async () => {
    try {
      const { data } = await CategoryServices.getCategories();
      if (data === null) return;
      setCategoryData(data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* API FOR GET ORDER NUMBER
  const getFliterOrders = async (page, filter) => {
    setLoading(true);
    try {
      const Page = page ? page : currentPage;
      const Filter = filter ? filter : filterObj;
      setCurrentPage(Page);
      setFilterObj(Filter);
      let param = {
        page: page ? page : 1,
        limit: 10,
        ...Filter,
      };
      console.log(filter)
      const { data, orderCount, footerBalance, footerPaid, footerTotal } = await OrdersServices.getFliterOrders(param);
      if (data === null) return;
      setOrdersData(data);
      setTotalCount(orderCount);
      setBalance(footerBalance);
      setFooterTotal(footerTotal);
      setPaid(footerPaid);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // getFliterOrders();
    getCategories();
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          borderRadius: "16px",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Assessment sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            General Report
          </Typography>
        </Box>
        <BreadCrumbs currentPage={["Report", "General Report"]} />
      </Box>
      <Box
        sx={{
          mt: 2,
          backgroundColor: colors.white,
          py: 3,
          borderRadius: "16px",
          boxShadow: colors.cardShadow,
        }}
      >
        <TableFilter
          type={"report"}
          sendFilterObj={(filter) => getFliterOrders(undefined, filter)}
          // action={(orderFrom, orderTo, deliveryFrom, deliveryTo, status, category) => getFliterOrders(undefined, orderFrom, orderTo, deliveryFrom, deliveryTo, status, category)}
          categoryData={categoryData}
        />
      </Box>

      <PrimaryTable
        // loading={loading}
        tableHead={tableHeader}
        tableData={ordersData}
        totalCount={totalCount}
        pageSize={pageLimit}
        filterObj={filterObj}
        currentPage={currentPage}
        balance={balance}
        paid={paid}
        footerTotal={footerTotal}
        changePage={(page, orderFrom) => getFliterOrders(page, undefined)}
      />
    </Fragment>
  );
};

export default Report;

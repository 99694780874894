import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { Error } from "../UI/Error";

function SelectField({ data, label, error, register, disable, stateValue, setState }) {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disable}
        value={stateValue ?? ""}
        label={label}
        sx={{
          textAlign: "left",
          mb: error ? "0px" : 2,
          borderRadius: 2.4,
        }}
        error={error && true}
        {...register}
      >
        {data?.map((item, index) => (
          <MenuItem
            key={index}
            value={item.name ? item.name : item}
            onClick={() => {
              setState(item);
            }}
          >
            {item.name ? item.name : item}
          </MenuItem>
        ))}
      </Select>
      {error && <Error message={error} />}
    </FormControl>
  );
}

export default SelectField;

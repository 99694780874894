import { Login } from "../views/Auth/Login";

const PublicRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
];

export default PublicRoutes;

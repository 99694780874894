import React, { Fragment } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import colors from "../../style/colors";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import InputField from "../../components/TextField/InputField";
import { PersonAdd, SaveAltTwoTone, ArrowBack } from "@mui/icons-material";
import { useState } from "react";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/Toaster";
import ClientsServices from "../../apis/Clients/ClientsServices";
import { useForm } from "react-hook-form";
import SelectField from "../../components/SelectField/SelectField";
import { useNavigate } from "react-router-dom";

const CreateClient = () => {
  const navigate = useNavigate();
  // *For States Selection
  const statesName = ["Abu Dhabi", "Dubai", "Sharjah", "Umm Al Qaiwain", "Fujairah", "Ajman", "Ras Al Khaimah"];
  const [selectedState, setSelectedState] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //* API FOR CREATE CLIENTS
  const createClient = async (formData) => {
    try {
      const obj = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        state: formData.state,
      };
      const { data } = await ClientsServices.createClients(obj);
      if (data === null) return;
      navigate("/clients");
      SuccessToaster("Client Created Successfully");
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "16px",
          flexWrap: "wrap",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PersonAdd sx={{ mr: 1 }} />
          <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            Create Client
          </Typography>
        </Box>
        <BreadCrumbs currentPage={["Create Client"]} />
      </Box>
      <Grid container>
        <Typography component="form" onSubmit={handleSubmit(createClient)}>
          <Grid item xs={12}>
            <Box
              sx={{
                mt: 2,
                p: 5,
                bgcolor: colors.white,
                borderRadius: "16px",
                boxShadow: colors.cardShadow,
                height: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputField
                    label="Name"
                    error={errors?.name?.message}
                    register={register("name", {
                      required: "Enter Client's name",
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputField
                    label="Email"
                    error={errors?.email?.message}
                    register={register("email", {
                      required: "Enter Client's email",
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputField
                    label="Phone Number"
                    error={errors?.phone?.message}
                    register={register("phone", {
                      required: "Enter Client's phone number",
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SelectField
                      label="State"
                      setState={(item) => {
                        setSelectedState(item);
                      }}
                      stateValue={selectedState}
                      data={statesName}
                      error={errors?.state?.message}
                      register={register("state", {
                        required: "Select Client's state",
                      })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    label="Address"
                    error={errors?.address?.message}
                    register={register("address", {
                      required: "Enter Client's address",
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      py: 1,
                    }}
                  >
                    <Button
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBack />}
                      sx={{
                        mr: 1,
                        minWidth: 100,
                        fontSize: "14px",
                        textTransform: "none",
                        color: colors.white,
                        bgcolor: colors.lightFont,
                        borderRadius: "8px",
                        "&:hover": {
                          bgcolor: colors.secondary,
                          transition: "0.3s ease-in-out",
                        },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      startIcon={<SaveAltTwoTone />}
                      sx={{
                        minWidth: 100,
                        fontSize: "14px",
                        textTransform: "none",
                        color: colors.white,
                        bgcolor: colors.primary,
                        borderRadius: "8px",
                        "&:hover": {
                          bgcolor: colors.sideTab,
                          transition: "0.3s ease-in-out",
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Typography>
      </Grid>
    </Fragment>
  );
};

export default CreateClient;

import React, { Fragment, useState } from "react";
import { Avatar, Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ArrowBack, CameraAlt, ListAlt, Print } from "@mui/icons-material";
import colors from "../../style/colors";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { getDate } from "../../utils/DateFormate";
import { imageBaseUrl } from "../../utils";
import OrdersServices from "../../apis/Orders/OrdersServices";
import { ErrorToaster } from "../../components/Toaster/Toaster";
import { useEffect } from "react";
import { Loading } from "../../components/UI/Loader";
import AuthServices from "../../apis/Auth/AuthServices";
import { Page, Text, View, Document, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "../../components/PdfDocument/PdfDocument";
import { SecondaryButton } from "../../components/Button/SecondaryButton";
const tableHeader = ["#", "Category", "Name", "Size", "Image", "Qty", "Unit Price", "Total"];
const styles = StyleSheet.create({
  container: {
    padding: "20px",
    fontFamily: "Arial",
  },
});
const OrderDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState();
  const [userData, setUserData] = useState("");

  const [ordersData, setOrdersData] = useState([]);

  const [pdfDialog, setPdfDialog] = useState(false);

  const [showPDF, setShowPDF] = useState(false);

  const handleClick = () => {
    setShowPDF(true);
    setPdfDialog(!pdfDialog);
  };

  const handlePDFViewerClose = () => {
    setShowPDF(false);
  };

  //* API FOR GET ORDER DETAILS
  const orderDetail = async () => {
    setLoading(true);
    try {
      const { data } = await OrdersServices.orderDetail(id);
      if (data === null) return;
      setOrdersData(data[0]);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //* API FOR GET USER INFO
  const getInfo = async () => {
    setLoading(true);
    try {
      const { data } = await AuthServices.userInfo();
      if (data === null) return;
      setUserData(data[0]);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    orderDetail();
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      {showPDF && <PdfDocument open={pdfDialog} onClose={() => setPdfDialog(false)} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          borderRadius: "16px",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ListAlt sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            Order Details
          </Typography>
        </Box>
        <BreadCrumbs currentPage={["Orders", "Order List", "Order Details"]} />
      </Box>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "end", pt: 1 }}>
          <SecondaryButton onClick={() => handleClick()} title="Download PDF" icon={<Print />} />
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Loading />
        </Box>
      ) : (
        <Box
          sx={{
            my: 2,
            bgcolor: colors.white,
            // p: 3,
            borderRadius: 3,
            boxShadow: "rgb(145 158 171 / 16%) 0px 4px 8px 0px",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Box sx={{ px: 10, pt: 8 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={imageBaseUrl + userData?.image} alt="Logo" style={{ width: "80px", height: "80px" }} />
                  <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold", color: colors.lightFont }}>
                      {userData?.name}
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.lightFont, width: "400px" }}>
                      {userData?.address}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ my: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ width: "100%", height: "38px", bgcolor: "#FFC809" }}></Box>
                <Box sx={{ bgcolor: colors.white, px: 4 }}>
                  <Typography alignSelf={"center"} variant="h2" sx={{ fontWeight: "bold", bgcolor: colors.white }}>
                    INVOICE
                  </Typography>
                </Box>
                <Box sx={{ bgcolor: "#FFC809", width: "40%", height: "38px" }}></Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ py: 5, px: 10 }}>
            <Grid container>
              <Grid item xs={6} sm={8} sx={{ textAlign: "left", px: 1 }}>
                <Typography variant="h6" paragraph sx={{ fontWeight: "bold", mb: 1, color: colors.lightFont }}>
                  Invoice to:
                </Typography>
                <Typography sx={{ mb: 1, fontSize: "0.9rem", fontWeight: "bold" }}>{ordersData?.client_name}</Typography>
                <Typography sx={{ mb: 1, fontSize: "0.8rem", fontWeight: "bold" }}>{ordersData?.client_address}</Typography>
                <Typography sx={{ mb: 1, fontSize: "0.8rem", fontWeight: "bold" }}>{ordersData?.client_phone}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ py: 2, px: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: colors.lightFont, fontSize: "0.9rem" }}>
                    Invoice No.
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: colors.lightFont, fontSize: "0.9rem" }}>
                    {ordersData?.order_no}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: colors.lightFont, fontSize: "0.9rem" }}>
                    Order Date
                  </Typography>
                  <Typography sx={{ mb: 1, fontSize: "0.9rem" }}>{getDate(ordersData?.order_date)}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: colors.lightFont, fontSize: "0.9rem" }}>
                    Delivery Date
                  </Typography>
                  <Typography sx={{ mb: 1, fontSize: "0.9rem" }}>{getDate(ordersData?.delivery_date)}</Typography>
                </Box>
              </Grid>

              <Box sx={{ width: "100%", pt: 5 }}>
                <TableContainer sx={{ width: "100%", position: "relative" }}>
                  <Table sx={{ border: "1px solid black" }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: "#373B46",
                          "&:last-child td, &:last-child th": {
                            border: 0,
                            pt: 1,
                          },
                        }}
                      >
                        {tableHeader.map((item, index) => (
                          <TableCell align="left" key={index}>
                            <Typography
                              sx={{
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                verticalAlign: "inherit",
                                textAlign: "left",

                                color: colors.white,
                              }}
                            >
                              {item}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ordersData?.detailData?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            bgcolor: index % 2 !== 1 && colors.hexGray,
                            "&:last-child td, &:last-child th": {
                              border: 0,
                              pt: 1,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Typography variant="h6" sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                                {item.category_name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6" sx={{ fontSize: "0.875rem", fontWeight: 600 }}>
                              {item.product_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.size ?? "--"}</Typography>
                          </TableCell>
                          <TableCell>{item?.image ? <Avatar src={imageBaseUrl + item.image} alt="avt" /> : <Typography>--</Typography>}</TableCell>
                          <TableCell>
                            <Typography>{item.quantity}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.unit_price}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.row_total}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            {/* ========== Invoice Summary ========== */}
            <Grid container spacing={1} sx={{ my: 1 }} justifyContent="space-between">
              <Grid item md={6}>
                <Typography variant="body1" sx={{ py: 5, fontWeight: "bold" }}>
                  Thank you For Your Business
                </Typography>
              </Grid>
              <Grid item md={4}>
                {/* <Box sx={{ bgcolor: colors.bgColor, p: 1, borderRadius: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Summary
                  </Typography>
                </Box> */}
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">SubTotal</Typography>
                  <Typography variant="body1">AED {ordersData?.sub_total}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Vat</Typography>
                  <Typography variant="body1">AED 5%</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Total</Typography>
                  <Typography variant="body1">AED {ordersData?.total}</Typography>
                </Box>
              </Grid>
            </Grid>
            {/* ========== Advance in Paid ========== */}

            <Grid container spacing={1} sx={{ my: 1 }} justifyContent="end">
              <Grid item md={4}>
                <Box sx={{ bgcolor: "#FFC809", p: 1, borderRadius: 0.5 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Grand Total
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Advance-In-Paid</Typography>
                  <Typography variant="body1">AED {ordersData?.advance_in_paid}</Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Balance</Typography>
                  <Typography variant="body1">AED {ordersData?.balance}</Typography>
                </Box>
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  py: 1,
                }}
              >
                <Button
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBack />}
                  sx={{
                    mr: 1,
                    minWidth: 100,
                    fontSize: "14px",
                    textTransform: "none",
                    color: colors.white,
                    bgcolor: colors.lightFont,
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: colors.secondary,
                      transition: "0.3s ease-in-out",
                    },
                  }}
                >
                  Back
                </Button>
              </Box>
            </Grid> */}
          </Box>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item md={11}>
              <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="body1" sx={{ pl: 10, mb: 1, fontWeight: 700, color: colors.lightFont, fontSize: "0.9rem" }}>
                    Phone #
                  </Typography>
                  <Typography sx={{ mb: 1, fontSize: "0.9rem" }}>{userData?.phone}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: colors.lightFont, fontSize: "0.9rem" }}>
                    Email:
                  </Typography>
                  <Typography sx={{ mb: 1, fontSize: "0.9rem" }}>{userData?.email}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: colors.lightFont, fontSize: "0.9rem" }}>
                    Website:
                  </Typography>
                  <Typography sx={{ mb: 1, fontSize: "0.9rem" }}>{userData?.website}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
};

export default OrderDetail;

import { get, post, patch, deleted } from "../index";
import Clients from "./Clients.Routes";

const ClientsServices = {
  getClients: async () => {
    const data = await get(Clients.getClients);
    return data;
  },
  createClients: async (obj) => {
    const data = await post(Clients.createClients, obj);
    return data;
  },
  updateClients: async (id, obj) => {
    const data = await post(Clients.updateClients + `${id}`, obj);
    return data;
  },
  deleteClient: async (id) => {
    const data = await deleted(Clients.deleteClients + `${id}`);
    return data;
  },
  clientStatus: async (id, obj) => {
    const data = await post(Clients.clientStatus + `${id}`, obj);
    return data;
  },
};

export default ClientsServices;

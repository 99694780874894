import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TextField, Tooltip, Typography } from "@mui/material";
import { Add, Block, UploadFile, Delete, AddBox } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import colors from "../../style/colors";
import InputField from "../../components/TextField/InputField";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import AddClientDialog from "../../components/Dialog/ClientDialog/AddClientDialog";
import OrderField from "../../components/TextField/OrderField";
import SelectField from "../../components/SelectField/SelectField";
import ClientsServices from "../../apis/Clients/ClientsServices";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/Toaster";
import CategoryServices from "../../apis/Category/CategoryServices";
import OrdersServices from "../../apis/Orders/OrdersServices";
import { imageBaseUrl } from "../../utils";
import { Loading } from "../../components/UI/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { currentDate } from "../../utils/DateFormate";

const UpdateOrder = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const navigate = useNavigate();
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);

  //* For Add Client Dialog
  const [clientDialog, setClientDialog] = useState(false);

  const [clientsData, setClientsData] = useState([]);
  const [clientId, setClientId] = useState({ name: state?.data?.client_name });
  const [categoryData, setCategoryData] = useState([]);

  const [orderDate, setOrderDate] = useState(state?.data?.order_date);
  // eslint-disable-next-line no-unused-vars
  const [deliveryDate, setDeliveryDate] = useState(state?.data?.delivery_date);
  console.log("🚀 ~ file: UpdateOrder.js:45 ~ UpdateOrder ~ deliveryDate:", deliveryDate);

  // *For Select Invoice
  // eslint-disable-next-line no-unused-vars
  const [selectedCurrency, setSelectedCurrency] = useState("AED");

  // *For Products list
  const [products, setProducts] = useState([]);

  // *For Invoice Summary
  const [subTotal, setSubTotal] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(state?.data?.advance_in_paid ? state?.data?.advance_in_paid : 0);

  //*For Notes
  const [notes, setNotes] = useState(state?.data?.note);

  const defaultDate = () => {
    // setValue("order", state?.data?.order_date?.split("-")?.reverse()?.join("-"));
    setValue("order", currentDate(state?.data?.order_date));
    setValue("delivery", currentDate(state?.data?.delivery_date));
    setProducts(state?.data?.detailData);
  };

  // *For Update Product
  const handleUpdateProduct = (value, id, key) => {
    try {
      const index = products.findIndex((e) => e.id === id);
      let updateProduct = [...products];
      updateProduct[index][key] = value;
      console.log(updateProduct);
      setProducts(updateProduct);
    } catch (error) {
      console.log("file: index.js:64 => handleUpdateProduct => error", error);
    }
  };

  //* API FOR GET ALL CLIENTS
  const getClients = async () => {
    try {
      const { data } = await ClientsServices.getClients();
      if (data === null) return;
      setClientsData(data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* API FOR GET ALL CATEGORIES
  const getCategories = async () => {
    setLoading(true);
    try {
      const { data } = await CategoryServices.getCategories();
      if (data === null) return;
      setCategoryData(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //* API FOR CREATE ORDERS
  const updateOrders = async () => {
    try {
      products.forEach((element) => {
        delete element.id;
      });
      const newProducts = products.map((element) => ({ ...element, row_total: (element.quantity * element.unit_price).toString() }));
      const obj = {
        order_no: state?.data?.order_no,
        client_id: state?.data?.client_id,
        order_date: orderDate,
        delivery_date: deliveryDate,
        sub_total: subTotal,
        vat: 5,
        note: notes ? notes : "",
        total: total,
        advance_in_paid: advanceAmount ? advanceAmount : 0,
        balance: (Number(total) - Number(advanceAmount)).toFixed(2),
        orderdetails: newProducts,
      };

      const { data } = await OrdersServices.updateOrders(id, obj);
      if (data === null) return;
      SuccessToaster("Order Created Successfully");
      navigate("/orderlist");
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* IMAGE FOR CREATE ORDER
  const orderPicture = async (image, id, key) => {
    try {
      let formData = new FormData();
      formData.append("image", image);
      const { data } = await OrdersServices.imageOrder(formData);
      if (data === null) return;
      handleUpdateProduct(data, id, key);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    let subTotal = 0;
    let total = 0;
    products.forEach((element) => {
      let st = (element?.quantity ?? 0) * (element?.unit_price ?? 0);
      // let t = ((element?.tax ?? 0) / 100) * st;
      let t = (5 / 100) * st;
      let gt = st + t;
      subTotal += st;
      total += gt;
    });
    setSubTotal(Number(subTotal).toFixed(2));
    setTotal(Number(total).toFixed(2));
  }, [products]);

  useEffect(() => {
    defaultDate();
    getClients();
    getCategories();
  }, []);

  return (
    <Fragment>
      <AddClientDialog open={clientDialog} onClose={() => setClientDialog(false)} action={getClients} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          borderRadius: "16px",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AddBox sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            Update Order
          </Typography>
        </Box>
        <BreadCrumbs currentPage={["Orders", "Create Order"]} />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", pl: 16, py: 4 }}>
          <Loading />
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{
              my: 2,
              bgcolor: colors.white,
              p: 3,
              borderRadius: 3,
              boxShadow: "rgb(145 158 171 / 16%) 0px 4px 8px 0px",
            }}
          >
            <Grid container spacing={1} sx={{ my: 1 }}>
              <Grid item md={3} xs={3.4}>
                <InputField disable defaultValue={state?.data?.order_no} size={"small"} type={"number"} label={"Order No."} />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={4} xs={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <SelectField disable label="Client Name" data={clientsData} stateValue={clientId.name} setState={(item) => setClientId(item)} />
                </Box>
              </Grid>
              <Grid item md={2} xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <Box mb={2}>
                  <Button
                    disabled
                    onClick={() => {
                      setClientDialog(!clientDialog);
                    }}
                    sx={{ bgcolor: "#d3d3d3" }}
                    startIcon={<Block />}
                  >
                    <Typography variant="body2" p={0.5}>
                      Add User
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2.5,
                      },
                    }}
                  >
                    <TextField
                      label="Order Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      error={errors?.order?.message && true}
                      helperText={errors?.order?.message}
                      {...register("order", {
                        onChange: (e) => setOrderDate(e.target.value),
                        required: {
                          value: true,
                          message: "Please fill all fields",
                        },
                        // pattern: {message: "Enter User's First Name" },
                      })}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2.5,
                      },
                    }}
                  >
                    <TextField
                      label="Delivery Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      // onChange={(e) => setDeliveryDate(e.target.value)}
                      error={errors?.delivery?.message && true}
                      helperText={errors?.delivery?.message}
                      {...register("delivery", {
                        onChange: (e) => setDeliveryDate(e.target.value),
                        required: {
                          value: true,
                          message: "Please fill all fields",
                        },
                      })}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            {/* ========== Product ========== */}
            <Box
              sx={{
                mt: 4,
                mb: 1,
                width: "100%",
                overflow: "auto",
                overflowX: "scroll",
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "6px",
                  borderRadius: "12px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "14px",
                  cursor: "pointer",
                },
              }}
            >
              <Table sx={{ minWidth: 600 }}>
                <Box sx={{ bgcolor: colors.bgColor, mb: 1, p: 1, borderRadius: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Category
                      </Typography>
                    </Grid>
                    <Grid item xs={1.8} sm={2} md={2} lg={2} xl={2}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Product Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Size
                      </Typography>
                    </Grid>
                    <Grid item xs={1.8} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Quantity
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Unit Price
                      </Typography>
                    </Grid>
                    <Grid item xs={1.3} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Image
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                      <Typography variant="body1" sx={{ fontWeight: 700, textAlign: "center" }}>
                        Sub Total
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {products.length > 0 &&
                  products.map((item, index) => (
                    <Grid key={index} container spacing={1} alignItems={"center"}>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel>Category</InputLabel>
                          <Select
                            defaultValue={item?.category_name}
                            label="Category"
                            onChange={(e) => {
                              let sortedData = categoryData.find((item) => item.name === e.target.value);
                              handleUpdateProduct(sortedData.id, item?.id, "category_id");
                            }}
                            sx={{
                              textAlign: "left",
                              mb: 2,
                              borderRadius: 2,
                            }}
                          >
                            {categoryData.map((item, index) => (
                              <MenuItem key={index} value={item?.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1.8} sm={2} md={2} lg={2} xl={2}>
                        <OrderField label={"Product Name"} defaultValue={item?.product_name} onChange={(e) => handleUpdateProduct(e.target.value, item.id, "product_name")} />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <OrderField size={"small"} label={"Size"} defaultValue={item?.size} onChange={(e) => handleUpdateProduct(e.target.value, item.id, "size")} />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <OrderField size={"small"} type={"number"} label={"Quantity"} defaultValue={item?.quantity} onChange={(e) => handleUpdateProduct(e.target.value, item.id, "quantity")} />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <OrderField size={"small"} type={"number"} label={"Unit Price"} defaultValue={item?.unit_price} onChange={(e) => handleUpdateProduct(e.target.value, item.id, "unit_price")} />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {item.image ? (
                          <Box sx={{ position: "relative", display: "flex", justifyContent: "center" }}>
                            <Avatar src={imageBaseUrl + item.image} sx={{ minWidth: "50px", height: "50px" }} />
                            <IconButton
                              sx={{ position: "absolute", display: "flex", justifyContent: "center", width: 30, height: 30, borderRadius: 40, left: 35, top: 0 }}
                              onClick={() => handleUpdateProduct("", item.id, "image")}
                            >
                              <Tooltip title="delete">
                                <Delete sx={{ fontSize: "22px", color: colors.opal }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <span
                              className="btn btn-primary btn-file"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                color: "white",
                                height: "50px",
                                width: "fit-content",
                                padding: "3px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <IconButton>
                                <UploadFile sx={{ fontSize: "28px", color: colors.primBlue }} />
                              </IconButton>
                              <input
                                type="file"
                                style={{
                                  position: " absolute",
                                  filter: "alpha(opacity=0)",
                                  top: "0",
                                  right: "0",
                                  align: "right",
                                  minWidth: "100%",
                                  minHeight: "100%",
                                  fontSize: " 100px",
                                  opacity: "0",
                                  outline: "none",
                                  background: "white",
                                  cursor: "inherit",
                                  display: "block",
                                }}
                                onChange={(event) => {
                                  orderPicture(event.target.files[0], item.id, "image");
                                }}
                              />
                            </span>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <Typography variant="body1" sx={{ textAlign: "center" }}>
                          {selectedCurrency} {((item?.unit_price ?? 0) * (item?.quantity ?? 0)).toFixed(2)}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} xl={0.5}>
                      {products.length > 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            bgcolor: colors.flashWhite,
                            borderRadius: 4,
                            width: "fit-content",
                            px: 1,
                            py: 0.5,
                          }}
                        >
                          <IconButton disableRipple aria-label="delete" onClick={() => deleteProduct(item.id)}>
                            <Delete />
                          </IconButton>
                        </Box>
                      )}
                    </Grid> */}
                    </Grid>
                  ))}
                {/* <Box sx={{ my: 1 }}>
                <Button sx={{ bgcolor: colors.iconBg }} startIcon={<Add />} onClick={() => addProduct()}>
                  <Typography variant="body2" p={0.5}>
                    Add New Item
                  </Typography>
                </Button>
              </Box> */}
              </Table>
            </Box>

            {/* ========== Invoice Summary ========== */}
            <Grid container spacing={1} sx={{ my: 1 }} justifyContent="end">
              <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", py: 2, pl: 1 }}>
                <Grid item xs={6}>
                  <TextField
                    defaultValue={state?.data?.note}
                    label="Additional Notes"
                    multiline
                    rows={4}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: 8 },
                    }}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={8}>
                  <Box sx={{ bgcolor: colors.bgColor, p: 1, borderRadius: 2 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                      Summary
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                    <Typography variant="body1">SubTotal</Typography>
                    <Typography variant="body1">
                      {selectedCurrency} {subTotal}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                    <Typography variant="body1">Vat</Typography>
                    <Typography variant="body1">{selectedCurrency} 5%</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                    <Typography variant="body1">Total</Typography>
                    <Typography variant="body1">
                      {selectedCurrency} {total}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            {/* ========== Advance in Paid ========== */}
            <Grid container spacing={1} sx={{ my: 1 }} justifyContent="end">
              <Grid item md={4} sm={6} xs={8}>
                <Box sx={{ bgcolor: colors.bgColor, p: 1, borderRadius: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Total
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 1,
                    px: 1,
                  }}
                >
                  <Box>
                    <Typography variant="body1">Advance-in-paid</Typography>
                  </Box>
                  <Box sx={{ width: "100px", pl: 1 }}>
                    <OrderField
                      // label="Amount"
                      // error={errors?.amount?.message}
                      // register={register("amount", {
                      //   required: "Advance Amount",
                      // })}
                      defaultValue={advanceAmount}
                      onChange={(e) => setAdvanceAmount(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Balance</Typography>
                  <Typography variant="body1">
                    {selectedCurrency} {(Number(total) - Number(advanceAmount)).toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              py: 1,
            }}
          >
            <Button
              onClick={() => updateOrders()}
              sx={{
                minWidth: 120,
                fontSize: "14px",
                py: 1,
                textTransform: "none",
                color: colors.white,
                bgcolor: colors.primary,
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: colors.sideTab,
                  transition: "0.3s ease-in-out",
                },
              }}
            >
              Update Order
            </Button>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UpdateOrder;

import { createTheme, ThemeProvider } from "@mui/material";
import colors from "../style/colors";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import WebRoutes from "../routes/routes";
import { Layout } from "../layout/Layout";
import PublicRoutes from "../routes/public.Routes";
import { ToasterContainer } from "../components/Toaster/Toaster";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "#fff",
    },
    secondary: {
      main: colors.secondary,
      contrastText: "#fff",
    },
    background: {
      default: colors.hexGray,
    },
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
});

function App() {
  const { user } = useAuth();
  return (
    <ThemeProvider theme={theme}>
      <ToasterContainer />
      <BrowserRouter>
        <Routes>
          <Route element={user ? <Layout /> : <Navigate to="/login" />}>
            {WebRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Route>
          <Route element={user ? <Navigate to="/" /> : <Outlet />}>
            {PublicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

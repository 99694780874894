import React, { useState } from "react";

//* Components Import
import colors from "../../style/colors";
import { getDate } from "../../utils/DateFormate";
import { paymentRows } from "../../utils/DummyData";

//* MUI Imports
import { Box, Typography, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import { makeStyles } from "@mui/styles";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useEffect } from "react";
import { ErrorToaster } from "../Toaster/Toaster";
import ClientsServices from "../../apis/Clients/ClientsServices";

//* Data Table Styling
const StyledTableHead = styled(TableHead)(() => ({
  border: 0,
  "& .MuiTableCell-head": {
    border: 0,
  },
}));

const StyledTableBody = styled(TableBody)(() => ({
  border: 0,
  "& .MuiTableCell-body": {
    border: 0,
    fontWeight: 700,
    fontSize: "12px",
    minHeight: "16px",
    lineHeight: "16px",
    color: `${colors.secondary}`,
    boxSizing: "none",
    borderBottom: "none",
  },
}));
const tableHeader = ["Name", "Phone", "Email", "Address", "State"];
function PaymentReportsCard() {
  // *For GET CLIENTS API
  const [clientsData, setClientsData] = useState([]);
  const dataLimit = clientsData.slice(0, 4);
  // const [loader,setLoader] = useState(true);

  //* API FOR GET ALL CLIENTS
  const getClients = async () => {
    try {
      const { data } = await ClientsServices.getClients();
      if (data === null) return;
      setClientsData(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      // setLoader(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);
  return (
    <Card sx={{ width: "100%", borderRadius: "16px", boxShadow: colors.cardShadow }}>
      <CardContent sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="space-between" sx={{ py: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Recent Clients
          </Typography>
          <MoreHorizIcon />
        </Box>
        <Box>
          <TableContainer>
            <Table size="small">
              <StyledTableHead>
                <TableRow sx={{ backgroundColor: colors.hexGray }}>
                  {tableHeader.map((item, index) => (
                    <TableCell key={index}>{item}</TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {dataLimit.map((row, index) => {
                  if (index < 5) {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          bgcolor: index % 2 !== 0 && colors.bgColor,
                          "&:last-child td, &:last-child th": {
                            border: 0,
                            pt: 1,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Box display="flex" alignItems="center" pt={2}>
                            {/* <Avatar src={row.img} alt="RemySharp" sx={{ width: 32, height: 34, mr: 1 }} /> */}
                            {row.name}
                          </Box>
                        </TableCell>
                        {/* <TableCell>{getDate(row.updatedAt)}</TableCell> */}
                        <TableCell>{row.phone}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.address}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        {/* <TableCell>
                          <Typography fontFamily="Roboto" color={row.status === true ? colors.ufoGreen : colors.opal}>
                            {row.status === true ? (
                              <ArrowUpwardIcon
                                style={{
                                  color: colors.ufoGreen,
                                  fontSize: "16px",
                                }}
                              />
                            ) : (
                              <ArrowDownwardIcon
                                sx={{
                                  color: colors.opal,
                                  fontSize: "16px",
                                }}
                              />
                            )}
                            {`$${row.amount}`}
                          </Typography>

                          <Typography fontSize="10px">{row.status === true ? "Approve" : "Reject"}</Typography>
                        </TableCell> */}
                      </TableRow>
                    );
                  }
                })}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PaymentReportsCard;

import { Button, IconButton, Typography } from "@mui/material";
import React from "react";
import colors from "../../style/colors";
import { ArrowBack } from "@mui/icons-material";

export const SecondaryButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      startIcon={props.icon}
      sx={{
        minWidth: 120,
        fontSize: "15.5px",
        textTransform: "none",
        color: colors.white,
        bgcolor: colors.secondary,
        borderRadius: "8px",
        "&:hover": {
          bgcolor: colors.lightFont,
          transition: "0.3s ease-in-out",
        },
      }}
    >
      {props.title}
    </Button>
  );
};

export const PrimButton = (props) => {
  return (
    <Button
      sx={{
        fontSize: "12px",
        textTransform: "none",
        color: colors.white,
        bgcolor: colors.primary,
        borderRadius: "8px",
        "&:hover": {
          bgcolor: colors.secondary,
          transition: "0.3s ease-in-out",
        },
      }}
      {...props}
    >
      <Typography variant="body2" p={0.5}>
        {props.title}
      </Typography>
    </Button>
  );
};

export const ActionButton = (props) => {
  return (
    <IconButton {...props} sx={{ mx: 0.5, p: 0 }}>
      {props.children}
    </IconButton>
  );
};

export const PaginationButton = (props) => {
  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        py: 0.5,
        mx: 0.5,
        textTransform: "capitalize",
        bgcolor: colors.white,
        color: colors.secondary,
        "&:hover": { bgcolor: colors.white, color: colors.secondary },
      }}
    >
      {props.children}
    </Button>
  );
};

export const BackButton = ({ onClick }) => {
  return (
    <IconButton
      aria-label="back"
      onClick={onClick}
      size="small"
      sx={{
        bgcolor: colors.tertiary,
        color: colors.white,
        "&:hover": {
          bgcolor: colors.tertiary,
          color: colors.white,
        },
      }}
    >
      <ArrowBack />
    </IconButton>
  );
};

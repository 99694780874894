import { get, post, patch, deleted } from "../index";
import Stats from "./Stats.Routes";

const StatsServices = {
  cardStats: async () => {
    const data = await get(Stats.cardStats);
    return data;
  },
  chartData: async () => {
    const data = await get(Stats.chartData);
    return data;
  },
};

export default StatsServices;

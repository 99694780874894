import { FormControl, TextField } from "@mui/material";
import React from "react";

const OrderField = ({ value, label, placeholder, error, register, type, readOnly, defaultValue, disable, onChange, onBlur }) => {
  return (
    <FormControl variant="standard" fullWidth sx={{ "& .MuiOutlinedInput-root": { borderRadius: "8px" } }}>
      <TextField
        size="small"
        disabled={disable}
        inputProps={{
          style: { borderRadius: 10 },
          min: 0,
          readOnly: readOnly ? true : false,
        }}
        sx={{
          mb: error ? "0px" : 2,
        }}
        variant="outlined"
        label={label}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        type={type}
        error={error && true}
        onChange={onChange}
        {...register}
      />
      {/* {error && (
        <Error message={error} />
      )} */}
    </FormControl>
  );
};

export default OrderField;

import { get, post, patch, deleted } from "../index";
import AuthRoutes from "./Auth.Routes";

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  userInfo: async () => {
    const data = await get(AuthRoutes.userInfo);
    return data;
  },
  updateInfo: async (obj) => {
    const data = await post(AuthRoutes.updateInfo, obj);
    return data;
  },
  updateProfileImage: async (obj) => {
    const data = await post(AuthRoutes.updateProfileImage, obj);
    return data;
  },
  logout: async () => {
    const data = await post(AuthRoutes.logout);
    return data;
  },
  changePassword: async (obj) => {
    const data = await post(AuthRoutes.changePassword, obj);
    return data;
  },
};

export default AuthServices;

import React, { Fragment, useState, useEffect } from "react";
import colors from "../../style/colors";
//* MUI Imports
import { Box, Typography, Divider } from "@mui/material";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import { integration } from "../../utils/DummyData";
import OrdersServices from "../../apis/Orders/OrdersServices";
import { ErrorToaster } from "../Toaster/Toaster";
import { Loading } from "../UI/Loader";
import { useNavigate } from "react-router-dom";

const SalesReportCard = () => {
  const navigate = useNavigate();
  const [ordersData, setOrdersData] = useState([]);
  let topOrders = ordersData.slice(0, 5);
  const [loading, setLoading] = useState(true);

  //* API FOR GET ORDER NUMBER
  const getOrders = async () => {
    setLoading(true);
    try {
      const { data } = await OrdersServices.getOrders();
      if (data === null) return;
      setOrdersData(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        py: 2,
        px: 3,
        borderRadius: "16px",
        height: 380,
        minHeight: 380,
        boxShadow: colors.cardShadow,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Fragment>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "bold", mb: 2, fontSize: "16px" }}>Sale Overview</Typography>
        </Box>
        {loading ? (
          <Box display="flex" justifyContent="center" pl={26} py={4}>
            <Loading />
          </Box>
        ) : (
          <Fragment>
            {topOrders.map((item, index) => (
              <Box key={index}>
                <Box
                  onClick={() => navigate(`/orderdetail/${item.id}`, { state: { data: item } })}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box>
                    <Typography sx={{ mr: 2, color: colors.secondary }}>{item.client_name}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "13px", color: colors.ebonyClay, mr: 2 }}>Order No: {item.order_no}</Typography>
                    <DataSaverOnIcon
                      sx={{
                        color: colors.primary,
                        backgroundColor: colors.bg2,
                        p: 0.5,
                        borderRadius: "6px",
                        fontSize: "32px",
                      }}
                    />
                  </Box>
                </Box>
                <Divider
                  sx={{
                    color: colors.textSecondary,
                    width: "100%",
                    my: 2,
                  }}
                />
              </Box>
            ))}
          </Fragment>
        )}
      </Fragment>
    </Box>
  );
};

export default SalesReportCard;

const Image = {
  logo: require("./images/furnituree.png"),
  bgImage: require("./images/bigim.jpeg"),
  sideNav: require("./images/side.jpeg"),
};

export default Image;

export const SvgIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <path fill="#FFF" d="M141.968 167.139H48.764a11.932 11.932 0 0 1-11.921-11.921V45.758a11.935 11.935 0 0 1 11.921-11.922h86.712l18.414 14.677v106.705a11.937 11.937 0 0 1-11.922 11.921z" />
      <path
        fill="#C4CDD5"
        d="M122.521 69.096h-62.5a1.986 1.986 0 1 1 0-3.97h62.5a1.984 1.984 0 1 1 0 3.97zm8.19 6.7h-70.69a1.985 1.985 0 0 1 0-3.97h70.69a1.98 1.98 0 0 1 1.404.581 1.992 1.992 0 0 1 .581 1.404 1.98 1.98 0 0 1-1.226 1.834c-.24.1-.499.151-.759.151z"
      />
      <path
        fill="#00A955"
        d="M122.521 99.123h-62.5a1.986 1.986 0 1 1 0-3.97h62.5a1.984 1.984 0 1 1 0 3.97zm8.19 6.7h-70.69a1.98 1.98 0 0 1-1.834-1.226 1.986 1.986 0 0 1 1.835-2.745h70.689a1.995 1.995 0 0 1 1.404.582 1.988 1.988 0 0 1 0 2.807 1.99 1.99 0 0 1-1.404.582z"
      />
      <path
        fill="#DFE3E8"
        d="M122.522 129.149h-62.5a1.986 1.986 0 1 1 0-3.97h62.5a1.985 1.985 0 0 1 0 3.97zm8.189 6.701h-70.69a1.985 1.985 0 1 1 0-3.971h70.69c.526 0 1.031.209 1.403.582a1.981 1.981 0 0 1 0 2.807 1.986 1.986 0 0 1-1.403.582zm22.82-86.864h-14.424a3.973 3.973 0 0 1-3.971-3.971V34.361a.246.246 0 0 1 .14-.223.25.25 0 0 1 .262.029l18.147 14.376a.25.25 0 0 1 .081.276.249.249 0 0 1-.235.166z"
      />
      <path fill="#919EAB" fillOpacity=".24" d="M63.977 43.25A28.25 28.25 0 1 1 35.727 15a28.26 28.26 0 0 1 28.25 28.25z" />
      <path fill="#C8FACD" d="M62.975 43.249A27.249 27.249 0 1 1 35.726 16a27.258 27.258 0 0 1 27.25 27.249z" />
      <mask id="m" width="55" height="55" x="8" y="16" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M62.975 43.249A27.249 27.249 0 1 1 35.726 16a27.258 27.258 0 0 1 27.25 27.249z" />
      </mask>
      <g mask="url(#m)">
        <path
          fill="#FBCDBE"
          d="M36.143 29.428a9.6 9.6 0 0 1 9.6 9.6v1.057a2.058 2.058 0 0 1 1.714 2.029v2.4a2.058 2.058 0 0 1-1.767 2.037 9.606 9.606 0 0 1-5.433 7.668v3.152h.686c6.817 0 12.343 5.526 12.343 12.343v1.543H19v-1.543c0-6.817 5.526-12.343 12.343-12.343h.686V54.22a9.607 9.607 0 0 1-5.434-7.668 2.058 2.058 0 0 1-1.766-2.037v-2.4c0-1.02.74-1.866 1.714-2.029v-1.057a9.6 9.6 0 0 1 9.6-9.6z"
        />
        <path
          fill="#007B55"
          d="M27.743 58.63c0 2.792 3.799 5.054 8.486 5.054 4.686 0 8.485-2.262 8.485-5.053a3.08 3.08 0 0 0-.078-.689c5.013 1.57 8.65 6.25 8.65 11.78v1.535H19v-1.534c0-5.598 3.727-10.326 8.834-11.837-.06.243-.091.492-.091.745z"
        />
        <path
          fill="#5BE584"
          d="M27.743 58.63c0 2.792 3.799 5.054 8.486 5.054 4.686 0 8.485-2.262 8.485-5.053a3.08 3.08 0 0 0-.078-.689c5.013 1.57 8.65 6.25 8.65 11.78v1.535H19v-1.534c0-5.598 3.727-10.326 8.834-11.837-.06.243-.091.492-.091.745z"
        />
        <path fill="#007B55" d="M31.857 48.904c.242 1.65 2.068 2.981 4.285 2.981 2.224 0 4.054-1.34 4.286-3.13.018-.069-.038-.298-.372-.298H32.23c-.307 0-.395.212-.373.447z" opacity=".24" />
        <path fill="#007B55" fillRule="evenodd" d="M34.085 45.542c0 .758.922 1.372 2.058 1.372 1.136 0 2.057-.614 2.057-1.372" clipRule="evenodd" opacity=".24" />
        <path
          fill="#007B55"
          d="M31.685 43.485a1.029 1.029 0 1 0 0-2.057 1.029 1.029 0 0 0 0 2.057zm8.915 0a1.029 1.029 0 1 0 0-2.057 1.029 1.029 0 0 0 0 2.057zm-11.377-3.23c.67-.944 2.51-1.474 4.095-1.084a.343.343 0 0 0 .164-.666c-1.84-.452-3.971.161-4.819 1.353a.343.343 0 1 0 .56.397zm13.84 0c-.67-.944-2.51-1.474-4.096-1.084a.343.343 0 1 1-.163-.666c1.84-.452 3.97.161 4.818 1.353a.343.343 0 1 1-.559.397z"
        />
        <path
          fill="#00AB55"
          d="M36.143 26.857c6.438 0 11.657 5.219 11.657 11.657v5.272c.15 1.68.29 2.858.421 3.532.236 1.218.976.816.976 2.548 0 1.732-.742 2.314-.745 3.855-.003 1.54 2.64 2.765 2.64 4.72 0 1.956-1.213 11.183-13.307 11.183-2.584 0-4.784-.735-6.602-2.205.117 1.113.228 2.392.331 3.838h-8.057c.16-4.075-1.248-6.354-1.248-10.895 0-4.541 2.337-11.146 2.277-12.762l.01-.359a11.75 11.75 0 0 1-.01-.498v-8.229c0-.68.058-1.348.17-1.997l.001-.06.007.013c.967-5.463 5.738-9.613 11.479-9.613zm.086 5.486h-.172a9.343 9.343 0 0 0-9.343 9.342v3.943a9.343 9.343 0 0 0 9.343 9.343h.172a9.343 9.343 0 0 0 9.343-9.343v-3.943a9.343 9.343 0 0 0-9.343-9.342z"
        />
        <path
          fill="#007B55"
          fillRule="evenodd"
          d="M36.143 28.742c5.87 0 10.628 4.72 10.628 10.543 0 .671-.063 1.328-.184 1.964-.272-5.533-4.844-9.935-10.444-9.935-5.6 0-10.172 4.402-10.445 9.935a10.5 10.5 0 0 1-.184-1.964c0-5.822 4.759-10.543 10.629-10.543z"
          clipRule="evenodd"
        />
        <path
          fill="#007B55"
          fillRule="evenodd"
          d="M47.399 59.17c.024.054.047.11.067.167.776 2.132-1.384 4.876-4.826 6.128-3.44 1.253-6.86.54-7.636-1.592a2.559 2.559 0 0 1-.056-.173c1.405.97 4.181 1.016 7.04-.025 2.855-1.04 4.95-2.854 5.408-4.497l.003-.009zm-1.99-2.631.029.131c.293 1.662-2.27 3.503-5.723 4.112s-6.49-.245-6.784-1.907a1.44 1.44 0 0 1-.017-.133c1.112.854 3.685 1.15 6.555.644 2.865-.505 5.179-1.66 5.935-2.841l.004-.006zm.334-13.482V47a9.343 9.343 0 0 1-9.343 9.342h-.515A9.343 9.343 0 0 1 26.543 47v-3.943c0-.634.063-1.253.183-1.852a9.61 9.61 0 0 0-.012.48v3.943a9.343 9.343 0 0 0 9.343 9.343h.171a9.343 9.343 0 0 0 9.343-9.343v-3.943c0-.16-.004-.32-.012-.48a9.38 9.38 0 0 1 .184 1.852z"
          clipRule="evenodd"
          opacity=".9"
        />
      </g>
      <path
        fill="#919EAB"
        fillOpacity=".24"
        d="M195.516 99.234a28.263 28.263 0 0 1-19.503 26.869c-.17.054-.339.108-.511.159a27.815 27.815 0 0 1-6.4 1.161 28.16 28.16 0 0 1-11.436-1.613 28.257 28.257 0 0 1-18.407-22.832 28.253 28.253 0 1 1 56.257-3.744z"
      />
      <path
        fill="#C8FACD"
        d="M194.514 99.233a27.256 27.256 0 0 1-18.812 25.917c-.164.052-.327.104-.493.153a26.765 26.765 0 0 1-6.173 1.12 27.206 27.206 0 0 1-22.99-10.083 27.258 27.258 0 0 1-3.745-28.04 27.255 27.255 0 0 1 23.152-16.255 27.254 27.254 0 0 1 29.061 27.188z"
      />
      <mask id="b" width="55" height="56" x="140" y="71" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M194.514 99.233a27.256 27.256 0 0 1-18.812 25.917c-.164.052-.327.104-.493.153a26.765 26.765 0 0 1-6.173 1.12 27.206 27.206 0 0 1-22.99-10.083 27.258 27.258 0 0 1-3.745-28.04 27.255 27.255 0 0 1 23.152-16.255 27.254 27.254 0 0 1 29.061 27.188z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#FBCDBE"
          d="M166.886 85.428c5.301 0 9.6 4.298 9.6 9.6v1.057a2.057 2.057 0 0 1 1.714 2.029v2.4a2.058 2.058 0 0 1-1.767 2.037 9.606 9.606 0 0 1-5.433 7.668v3.152h.686c6.816 0 12.342 5.526 12.342 12.343v1.543h-34.285v-1.543c0-6.817 5.526-12.343 12.343-12.343h.685v-3.152a9.606 9.606 0 0 1-5.433-7.668 2.058 2.058 0 0 1-1.767-2.037v-2.4c0-1.02.742-1.866 1.715-2.029v-1.057a9.6 9.6 0 0 1 9.6-9.6z"
        />
        <path
          fill="#C8FACD"
          d="M171.686 113.38c6.816 0 12.342 5.526 12.342 12.343v1.534h-34.285v-1.534c0-6.817 5.526-12.343 12.343-12.343h.204c-.022.169-.033.34-.033.514 0 2.367 2.072 4.286 4.629 4.286 2.556 0 4.628-1.919 4.628-4.286 0-.174-.011-.345-.033-.514h.205z"
        />
        <path
          fill="#00AB55"
          fillRule="evenodd"
          d="M160.714 120.143c0 2.607.336 5.043.919 7.114h-11.891v-1.534c0-6.632 5.231-12.043 11.792-12.331-.522 1.99-.82 4.294-.82 6.751zm23.314 5.58v1.534h-11.719c.583-2.071.919-4.507.919-7.114 0-2.453-.298-4.754-.818-6.742 6.479.375 11.618 5.748 11.618 12.322z"
          clipRule="evenodd"
        />
        <path fill="#C8FACD" fillRule="evenodd" d="m175.285 123.143 1.638-1.229a.686.686 0 0 1 .833.007l1.129.879-3.6.343z" clipRule="evenodd" />
        <path
          fill="#005249"
          fillRule="evenodd"
          d="M161.571 113.2c-.686 3.314-.4 8 .857 14.057h-2.743l-2.4-6.172 1.029-1.542-1.029-1.029 3.257-5.143a3.682 3.682 0 0 1 1.029-.171zm10.629 0c.685 3.314.4 8-.858 14.057h2.743l2.4-6.172-1.028-1.542 1.028-1.029-3.257-5.143a3.669 3.669 0 0 0-1.028-.171z"
          clipRule="evenodd"
        />
        <path
          fill="#5BE584"
          fillRule="evenodd"
          d="M171 112.092c1.161.788 1.885 1.906 1.885 3.146 0 1.268-.759 2.409-1.968 3.2l-1.018-.802-.785.056.171-.54-.013-.01c1.046-.539 1.728-1.422 1.728-2.421v-2.629zm-6.501 5.05-.014.01.172.54-.785-.056-1.019.802c-1.186-.776-1.939-1.889-1.967-3.129l-.001-.071c0-1.24.725-2.358 1.886-3.146v2.629c0 .999.682 1.882 1.728 2.421z"
          clipRule="evenodd"
        />
        <path fill="#005249" fillRule="evenodd" d="M164.485 104.8a2.4 2.4 0 0 0 4.8 0m-4.457-3.258c0 .758.921 1.372 2.057 1.372s2.057-.614 2.057-1.372" clipRule="evenodd" opacity=".24" />
        <path
          fill="#005249"
          fillRule="evenodd"
          d="M160.055 99.409c.317.651 1.03 1.105 1.858 1.105.826 0 1.536-.451 1.855-1.098.094-.193-.04-.35-.176-.234-.42.362-1.017.587-1.679.587-.641 0-1.221-.211-1.639-.553-.152-.125-.311.002-.219.193zm9.943 0c.317.651 1.029 1.105 1.858 1.105.826 0 1.536-.451 1.854-1.098.095-.193-.04-.35-.175-.234-.42.362-1.017.587-1.679.587-.641 0-1.221-.211-1.639-.553-.153-.125-.311.002-.219.193z"
          clipRule="evenodd"
        />
        <path
          fill="#005249"
          d="M159.965 96.255c.671-.944 2.511-1.474 4.096-1.084a.343.343 0 0 0 .164-.666c-1.841-.452-3.972.161-4.819 1.353a.343.343 0 0 0 .559.397zm13.841 0c-.671-.944-2.511-1.474-4.096-1.084a.343.343 0 0 1-.164-.666c1.841-.452 3.972.161 4.819 1.353a.343.343 0 1 1-.559.397z"
        />
        <path
          fill="#005249"
          d="M166.971 107.687c-1.95.064-2.82 1.008-3.475.049-.491-.72-.286-1.93.174-2.61.653-.969 1.54-.501 2.532-.611.269-.03.54-.106.769-.23.229.124.5.2.769.23.992.11 1.878-.358 2.532.611.46.68.665 1.89.174 2.61-.655.959-1.525-.114-3.475-.049zm9.466-11.63c-.58 2.412-.846 4.874-1.267 7.315a53.543 53.543 0 0 1-.285 1.522c-.022.106-.043.501-.146.551-.314.151-.953-.655-1.124-.837-.429-.457-.854-.918-1.377-1.276a7.342 7.342 0 0 0-3.579-1.255c-.538-.043-1.266.032-1.774.341-.507-.309-1.235-.384-1.773-.341a7.342 7.342 0 0 0-3.579 1.255c-.523.358-.948.819-1.377 1.276-.171.182-.81.988-1.124.837-.103-.05-.124-.445-.146-.551a53.543 53.543 0 0 1-.285-1.522c-.421-2.441-.687-4.903-1.267-7.315-.169 0-.317 3.214-.336 3.513-.077 1.216-.166 2.405-.052 3.623.197 2.099.403 4.732 1.984 6.336 1.428 1.449 3.502 1.752 5.291 2.666.231.118.536.263.866.382.347.268 1.035.451 1.828.451.832 0 1.548-.201 1.877-.491a7.76 7.76 0 0 0 .758-.342c1.788-.915 3.863-1.217 5.291-2.666 1.581-1.604 1.787-4.237 1.984-6.336.114-1.218.025-2.407-.052-3.623-.019-.3-.167-3.513-.336-3.513z"
        />
        <path
          fill="#007B55"
          fillRule="evenodd"
          d="M166.885 89.028c5.586 0 10.115 2.533 10.115 5.657 0 .442-.091.873-.263 1.286-1.041-2.505-5.057-4.371-9.852-4.371s-8.811 1.866-9.852 4.371a3.342 3.342 0 0 1-.262-1.286c0-3.124 4.528-5.657 10.114-5.657z"
          clipRule="evenodd"
        />
        <path
          fill="#005249"
          d="M167.228 79.771c5.117 0 14.043 7.569 14.057 15.656.008 4.367-1.649 7.035-4.971 8.001 1.048-9.58-5.417-11.757-9.434-14.088-4.021 2.33-10.475 4.507-9.423 14.088-3.322-.857-4.979-3.524-4.972-8.001.014-8.088 8.927-15.656 14.058-15.656.114 0 .228.005.341.015a3.94 3.94 0 0 1 .344-.015z"
        />
      </g>
      <path
        fill="#919EAB"
        fillOpacity=".24"
        d="M5 156.457a28.162 28.162 0 0 0 8.98 20.659 28.239 28.239 0 0 0 10.523 6.21 28.359 28.359 0 0 0 18.088-.201l.32-.115a28.255 28.255 0 0 0 16.203-37.926 28.251 28.251 0 0 0-38.905-13.686A28.248 28.248 0 0 0 5 156.457z"
      />
      <path
        fill="#C8FACD"
        d="M6 156.456a27.163 27.163 0 0 0 8.663 19.927 27.204 27.204 0 0 0 11.043 6.262 27.357 27.357 0 0 0 16.553-.466l.31-.111a27.253 27.253 0 0 0 6.316-47.931A27.253 27.253 0 0 0 6 156.456z"
      />
      <mask id="c" width="55" height="55" x="6" y="129" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M6 156.456a27.163 27.163 0 0 0 8.663 19.927 27.204 27.204 0 0 0 11.043 6.262 27.357 27.357 0 0 0 16.553-.466l.31-.111a27.253 27.253 0 0 0 6.316-47.931A27.253 27.253 0 0 0 6 156.456z"
        />
      </mask>
      <g mask="url(#c)">
        <path
          fill="#FBCDBE"
          d="M33.629 143.171a9.6 9.6 0 0 1 9.6 9.6v1.057a2.059 2.059 0 0 1 1.714 2.029v2.4a2.057 2.057 0 0 1-1.767 2.037 9.607 9.607 0 0 1-5.433 7.668v3.152h.686c6.816 0 12.343 5.526 12.343 12.343V185H16.486v-1.543c0-6.817 5.526-12.343 12.343-12.343h.685v-3.152a9.608 9.608 0 0 1-5.433-7.668 2.057 2.057 0 0 1-1.767-2.037v-2.4a2.06 2.06 0 0 1 1.715-2.029v-1.057a9.6 9.6 0 0 1 9.6-9.6z"
        />
        <path
          fill="#5BE584"
          d="M25.229 172.374c0 2.791 3.799 5.054 8.485 5.054 4.687 0 8.486-2.263 8.486-5.054a3.08 3.08 0 0 0-.078-.689c5.013 1.57 8.65 6.251 8.65 11.781V185H16.486v-1.534c0-5.598 3.726-10.325 8.834-11.837-.06.243-.091.492-.091.745z"
        />
        <path fill="#005249" d="M30.392 162.565a3.257 3.257 0 0 0 6.475-.015c.014-.132-.133-.35-.317-.35h-5.821c-.185 0-.356.202-.337.365z" opacity=".24" />
        <path fill="#005249" fillRule="evenodd" d="M31.571 159.286c0 .757.921 1.371 2.057 1.371 1.137 0 2.058-.614 2.058-1.371" clipRule="evenodd" opacity=".24" />
        <path
          fill="#005249"
          d="M29.172 157.229a1.029 1.029 0 1 0-.001-2.058 1.029 1.029 0 0 0 0 2.058zm8.914 0a1.029 1.029 0 1 0-.001-2.058 1.029 1.029 0 0 0 0 2.058zm-11.378-3.23c.671-.945 2.511-1.474 4.096-1.085a.343.343 0 1 0 .164-.666c-1.84-.452-3.971.161-4.819 1.353a.343.343 0 0 0 .56.398zm13.841 0c-.671-.945-2.511-1.474-4.096-1.085a.343.343 0 1 1-.163-.666c1.84-.452 3.97.161 4.818 1.353a.343.343 0 0 1-.559.398z"
        />
        <mask id="d" width="46" height="48" x="11" y="137" maskUnits="userSpaceOnUse">
          <path
            fill="#fff"
            d="M56.257 137v48h-5.486v-1.543c0-6.817-5.526-12.343-12.342-12.343h-.686v-3.152a9.606 9.606 0 0 0 5.45-7.842 2.058 2.058 0 0 0 1.75-2.034v-2.229a2.059 2.059 0 0 0-1.714-2.029v-1.057a9.566 9.566 0 0 0-.796-3.831c-1.701-2.854-16.122-2.717-17.597-.028a9.567 9.567 0 0 0-.807 3.859v1.057a2.059 2.059 0 0 0-1.715 2.029v2.229c0 1.031.76 1.886 1.75 2.034a9.606 9.606 0 0 0 5.45 7.842v3.152h-.685c-6.749 0-12.233 5.416-12.342 12.139l-.001.204V185H11v-48h45.257z"
          />
        </mask>
        <g mask="url(#d)">
          <path fill="#005249" fillRule="evenodd" d="M32.117 137.343h3.194a6.857 6.857 0 0 1 6.699 5.389l1.561 7.125H23.857l1.562-7.125a6.857 6.857 0 0 1 6.698-5.389z" clipRule="evenodd" />
          <path fill="#005249" d="M33.629 161.857c11.55 0 20.914-4.413 20.914-9.857 0-5.444-9.364-9.857-20.914-9.857-11.551 0-20.915 4.413-20.915 9.857 0 5.444 9.364 9.857 20.915 9.857z" />
          <path fill="#007B55" d="M33.629 155.343c5.87 0 10.628-1.919 10.628-4.286 0-2.367-4.758-4.286-10.628-4.286S23 148.69 23 151.057c0 2.367 4.759 4.286 10.629 4.286z" />
        </g>
        <path
          fill="#005249"
          d="M32.208 156.286c0 1.663-1.414 3.012-3.771 3.012h-.471c-2.357 0-3.3-1.35-3.3-3.012 0-1.664.22-3.013 3.52-3.013h.502c3.3 0 3.52 1.349 3.52 3.013zm10.555 0c0 1.663-1.414 3.012-3.771 3.012h-.472c-2.356 0-3.3-1.35-3.3-3.012 0-1.664.22-3.013 3.52-3.013h.503c3.299 0 3.52 1.349 3.52 3.013z"
          opacity=".7"
        />
        <path
          fill="#005249"
          d="M28.689 152.771c3.711 0 4.022 1.737 4.022 3.515 0 2.069-1.757 3.514-4.274 3.514h-.471c-2.381 0-3.802-1.314-3.802-3.514 0-1.778.31-3.515 4.022-3.515h.503zm0 1.004h-.503c-2.957 0-3.016 1.064-3.017 2.427v.084c0 1.145.485 2.51 2.797 2.51h.471c1.925 0 3.268-1.032 3.268-2.51v-.249c-.01-1.283-.18-2.262-3.016-2.262zm10.554-1.004c3.711 0 4.022 1.737 4.022 3.515 0 2.069-1.757 3.514-4.274 3.514h-.471c-2.38 0-3.802-1.314-3.802-3.514 0-1.778.31-3.515 4.022-3.515h.503zm0 1.004h-.503c-2.957 0-3.016 1.064-3.017 2.427v.084c0 1.145.486 2.51 2.797 2.51h.471c1.925 0 3.269-1.032 3.269-2.51v-.249c-.011-1.283-.181-2.262-3.017-2.262z"
        />
        <path
          fill="#005249"
          d="M23.16 153.776c.117-.118 1.404-1.005 5.026-1.005 3.037 0 3.68.323 4.652.81l.072.036c.067.024.413.144.818.158a2.85 2.85 0 0 0 .776-.152c1.11-.552 2.198-.852 4.739-.852 3.622 0 4.908.887 5.026 1.005.278 0 .503.224.503.502v.502a.502.502 0 0 1-.503.502s-1.005 0-1.005.502-.503-.727-.503-1.004v-.489c-.613-.24-1.71-.515-3.518-.515-2.014 0-3.018.193-3.878.556l.01.024-.428.173.427.172-.377.931-.466-.188a2.235 2.235 0 0 0-.256-.079c-.347-.087-.704-.114-1.02-.048a1.368 1.368 0 0 0-.321.11l-.45.225-.45-.898.45-.225h.001l-.43-.174.014-.034c-.743-.354-1.427-.545-3.883-.545-1.808 0-2.905.275-3.518.515v.489c0 .277-.503 1.506-.503 1.004s-1.005-.502-1.005-.502a.503.503 0 0 1-.503-.502v-.502c0-.278.225-.502.503-.502z"
        />
      </g>
    </svg>
  );
};

export const PersonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <path
        fill="url(#a)"
        d="M134.926 133.612c-1.2-3.2-5.8-4.1-8.1-1.6-1.3-7.8 5-32.3-8.9-32-72.8-.5-48.2-8-52.4 77.7-.1 4.8 4.1 9 8.9 8.9h43.5c14 .4 7.6-24.5 8.9-32.4 2.9 3.2 8.7.8 8.5-3.5-.1-1 .4-16.5-.4-17.1zm-2.5 18.3h-5.6v-17.4h5.6v17.4z"
      />
      <path fill="url(#b)" d="M74.426 190.212c.1 5.9-9.2 5.9-9.1 0-.1-5.9 9.2-5.9 9.1 0zm46.4-4.5c-5.9-.1-5.9 9.2 0 9.1 5.9.1 5.9-9.2 0-9.1z" />
      <path
        fill="#007B55"
        d="M73.126 99.513h2.5v87.499h-2.5v-87.5zm7.1 87.399h2.5v-87.4h-2.5v87.4zm7 0h2.5v-87.4h-2.5v87.4zm7.1 0h2.5v-87.4h-2.5v87.4zm7 0h2.5v-87.4h-2.5v87.4zm7.1 0h2.5v-87.4h-2.5v87.4zm7-87.4v87.5h2.5v-87.5h-2.5z"
      />
      <path
        fill="#00AB55"
        d="m140.026 60.912-1.8 6.7c-3.3-.9-7.3-.7-10.6.3.2-3.2 0-6.3-.6-9.4l-17.3-7.5c-2 14.2 1.4 21.6 7.4 32.8l-10.3 4.8c-1.7 4.1-4.1 4-7.1-.2-6.3 8.4-15.3-6.8-24.7-.3l-1.5-6.6-6.2.2c-5-10.2-13-36.6-1.2-43.9 0 0 0 .1.1.1 3-2 6.6-3.4 10.1-4.4l8.5 10.6c5.6-11.1 7.4-14.8 21.6-12.8l28.6 16.6c4.4 2.3 6.7 8 5 13z"
      />
      <path
        fill="#C8FACD"
        fillRule="evenodd"
        d="M107.626 52.712h.1v2.3h-.1v12.4c0 1-.9 1.9-1.9 1.9-1.7-.5-9.1 1.4-8.9-1.9v-18.1c0-1 .9-1.9 1.9-1.9.3 0 7.7-.1 7.7.1 1.9.6 1.1 3.8 1.2 5.2zm-4 .1c0 2.2-3.3 2.2-3.3 0 0-2.1 3.3-2.1 3.3 0z"
        clipRule="evenodd"
      />
      <path
        fill="#005249"
        d="m130.826 91.813-12.9 56.5c1.3 1.2 6.9 11.4 6.9 13.2-1.8 5.8-12.9 0-15.9-3.3-1.6-6.9 6-48.8 5.5-56.6-3.9-.3-10.4.4-14.1.8-6.6.2-15.7-1.3-22.4-.8l-.2 1.3 5.4 45.2h-.2c.3.2.7.5 1.1.8 0 1.9 1.5 7.7.3 9.4-3.4 3.7-9.2 5.9-13.5 5.7-1.7-.1-2.9-2-2.2-3.6.1-.1 6.1-12 6.3-11.5h-.4l-13-57.1c-1-4.3 1.5-9 5.7-10.4 0 .1.1.2.1.3l6.2-.2 1.5 6.6c.4-.2.7-.3 1.1-.5.9-.3 1.8-.6 2.7-.8 7.4-2.8 14.8 9.4 20.9 1.5 3 4.2 5.4 4.3 7.1.2l7.6-3.5c.6-1 1.5-1.9 2.4-2.5.2.5.5 1 .8 1.3 4.6-.3 8.2-1.4 12.5 2.3.9 1.7 1.1 3.8.7 5.7zm-58.1-68.2c0-.8.1-1.5.2-2.2-1-.9-.2-5.2 1.3-3.3.5-.9 1.1-1.6 1.9-2.3 2.8 6.1 11.8 2.8 16.5 2.1 1.5 2.3 2 5.4 1.4 8.1 9.6-28.2-30.8-27.7-21.1-.4-.1-.6-.2-1.3-.2-2z"
      />
      <path
        fill="#FBCDBE"
        d="m84.826 43.913-8.5-10.6c-.1 0-.2.1-.3.1l-.2-2.3c-2.4-2.4-3.6-6.3-2.8-9.7-1-.9-.2-5.2 1.3-3.3.5-.9 1.1-1.6 1.9-2.3 2.8 6.1 11.8 2.8 16.5 2.1 2.9 4.3 1.8 10.9-2.2 14l.2 1.7c-.2-.1-5.9 10.3-5.9 10.3zm42.8 23.9v-.7c-.2 3.4-1 6.8-2.2 10.1-1.4.3-7.5-.3-8.4 1.1-1.5 1.4-.2 5.3 1.5 5.9 4.5-1.6 7.7-1.2 11.7 1.9l7.2-18.6c-3.2-.8-6.8-.6-9.8.3zm-20.2-9.8c-3.8-2.8-20.3 9.5-25.7 10.2-2.6 3.7-1.8 7.4 1 11.2 5.4-2.3 11.6-6.5 17.8-11.4l5.8-1.5c3.9-.9 4.7-6.7 1.1-8.5z"
      />
      <defs>
        <linearGradient id="a" x1="64.751" x2="64.751" y1="99.643" y2="186.617" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BE584" />
          <stop offset="1" stopColor="#007B55" />
        </linearGradient>
        <linearGradient id="b" x1="95.286" x2="95.286" y1="280.421" y2="185.693" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BE584" />
          <stop offset="1" stopColor="#007B55" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CustomerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <path fill="url(#a)" d="M85.6 134.7c-3.7-5.5-33-10.5-35.3-12 0 0-5.4-2-7.8 3.4-2.4 5.4-16.6 49.8-16.6 49.8s27.8 14.9 36 16.5c3.3.4 6.9-1.1 9.7-2.9l15.8-47.2c-.1 0 2.1-5.2-1.8-7.6z" />
      <path
        fill="#005249"
        d="M85.6 134.7c2.6 4.5-17.3 52.2-17.2 55.4v.1c2.5 4-4.6 6.9-5.6 2.3-.3 0-.6 0-.9-.1.2-.1.5-.2.8-.4-.1-1.8 1.2-3.2 2.9-3.3 1.6-4.7 17-51 17.3-51.6 0 0-.5-3.5-1.8-5.7 1.8.7 3.4 1.7 4.5 3.3zm2.4-29.4c-.3.5-17.4-7.9-14.5.4l-8 21.2c1 .3 2 .6 3 .8 1.7-5.2 6.9-20.9 7.4-22.2.5-1.5 6 .8 8.1 1.4 1.6.4 1.1 2 1.1 2l-7.4 21.4c1 .3 1.8.5 2.5.7l6.7-21.2c4.2-4.2 1.1-4.5 1.1-4.5zm-61.9 70.5c-4 0-4 6.2 0 6.2s4-6.2 0-6.2zm30.1 13c-4 0-4 6.2 0 6.2s4-6.2 0-6.2zm116.3 5.4v.2h-19.1v-7.7c6.1.3 19.4-2.3 19.1 7.5zm-65-11.4-9.8-6.3-4.1 6.5 16.1 10.3c2.3-3.5 1.4-8.3-2.2-10.5zm56.3-2.1L156.7 94l-13.2-24.5-22.5 3.9c.8 15.9-1.6 50.8-3.2 66.8l-16.1 29.9 7.7 5.5 21.9-28.5 8.7-28.3 13.6 62.5 10.2-.6z"
      />
      <path fill="#5BE584" d="M138 57.5s4.6-5.5-.5-10.6c0 0-6.5-10.5-8.4-12.4 0 0 0 .2-10.9.6l-.2.2c12.8 2.3 18 22.9 12 38.7l14-3.5-6-13z" />
      <path
        fill="#00AB55"
        d="M161.4 93.8c2.4 8.2 3.9 14.1 3.9 14.1s-2.9.2-7.4.9L156.7 94c-4.4-7.8-15.8-28.3-18.8-36.4 0 0 4.7-5.5-.4-10.6 0 0-6.4-10.5-8.4-12.4 2.1-.3 4.4 1.5 6.8 4.1v-.2c10.1 13.8 23.7 37.9 33.3 51.8l-7.8 3.5zm-38.6-57c-6.8-4.5-16.7 2.2-15 10.2L80.7 92.9l8.8 3.6 23.8-31.3c.9 12.1-9.7 32.4-16.2 36.9-4.6 4.4 2 12.3 5.5 13.7v.1s7.7 3.6 17 3.5c.4-5.3 1.9-21.2 1.5-26.2 11.8-18.9 18.1-42.4 1.7-56.4z"
      />
      <path fill="#005249" d="M109.6 29.8c2.1-.4 11.1 1.4 13.3 1.8 1.1.1 2.1-.7 2-1.8 12.4.5 8.6-.2 9.2-10.7h3.1c.2-4.6-3.1-8.2-7-10.2-7.7-8.4-22.8-2-22.5 9.3.5 1.6-1.5 12 1.9 11.6z" />
      <path
        fill="#FBCDBE"
        d="m154.9 181.2 6.2-.4-.2 5.8h-6v-5.4zM99 177.3l5.2 3.3 4.6-5.5-5.8-4.1-4 6.3zM80.7 92.9l.9-1.6-5.2 6.4c-10 9.6 11.4 15.2 7.6 2.1l3.8-4-7.1-2.9zm95.5 4.9c-1-2.3-6.9-7.5-8.4-9.7l1.4 2.2-6.9 3.1 4 3.9c-3.5 9.4 12.5 10.2 9.9.5zm-52.9-80.6-.7 2h-.7c0 3.9 1.9 7.5 3.1 10.3 5-.6 8.7-5.2 8.4-10.3h-10l-.1-2z"
      />
      <defs>
        <linearGradient id="a" x1="25.9" x2="25.9" y1="122.338" y2="192.465" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BE584" />
          <stop offset="1" stopColor="#007B55" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const UploadImg = () => {
  return (
    <svg viewBox="0 0 480 360" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="a" x1="19.496%" x2="77.479%" y1="71.822%" y2="16.69%">
          <stop offset="0%" stop-color="#00AB55" />
          <stop offset="100%" stop-color="#00AB55" stop-opacity="0" />
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        d="M0 198.78c0 41.458 14.945 79.236 39.539 107.786 28.214 32.765 69.128 53.365 114.734 53.434a148.44 148.44 0 0 0 56.495-11.036c9.051-3.699 19.182-3.274 27.948 1.107a75.779 75.779 0 0 0 33.957 8.01c5.023 0 9.942-.494 14.7-1.433 13.58-2.67 25.94-8.99 36.09-17.94 6.378-5.627 14.547-8.456 22.897-8.446h.142c27.589 0 53.215-8.732 74.492-23.696 19.021-13.36 34.554-31.696 44.904-53.224C474.92 234.58 480 213.388 480 190.958c0-76.93-59.774-139.305-133.498-139.305-7.516 0-14.88.663-22.063 1.899C305.418 21.42 271.355 0 232.499 0a103.651 103.651 0 0 0-45.88 10.661c-13.24 6.487-25.011 15.705-34.64 26.939-32.698.544-62.931 11.69-87.676 30.291C25.351 97.155 0 144.882 0 198.781z"
        opacity=".2"
      />
      <defs>
        <linearGradient id="b" x1="30.113%" x2="30.113%" y1="0%" y2="100%">
          <stop offset="0%" stop-opacity="0" />
          <stop offset="100%" />
        </linearGradient>
      </defs>
      <path
        fill="#FF4842"
        d="M293.006 206.192c-2.248 2.672-4.676 2.628-6.123 2.251l.054-1.384s5.979-2.733 5.827-2.159c-.057.215.057.733.242 1.292zm16.387 3.025c-3.818 2.206-6.058-.38-6.578-1.112.32-.84.801-2.204.703-2.592-.144-.577 5.827 2.155 5.827 2.155l.048 1.549zm-4.09-22.013s3.632-2.093 4.425-1.151c.792.942-4.425 1.151-4.425 1.151zm2.194 1.151s-.598.299.721.681c1.318.383-.721-.681-.721-.681zm-21.965-5.756c-.123.036 3.022 2.123 5.862.395-.012 0-5.154-.61-5.862-.395zm5.707 3.992s-.861 1.692-2.625 1.943c-1.764.251 2.625-1.943 2.625-1.943zm11.546 3.671s4.138 6.578 3.346 8.129c-.793 1.552-3.346-8.129-3.346-8.129zm-8.165 26.154c0 .368 0 .712-.036.996-.107 1.33-3.381.828-3.381.828-2.99.416-4.066-1.019-4.422-2.392a5.444 5.444 0 0 1-.164-1.363 5.606 5.606 0 0 1 .054-.819v-.272a1.998 1.998 0 0 1 1.859.379c1.331 1.08 3.095.252 3.095.252s1.294.107 1.793.538c.281.242.745-.341 1.098-.897.021.359.054 1.007.074 1.701.024.356.03.714.03 1.049z"
        opacity=".05"
      />
      <path
        fill="#FF4842"
        d="M289.37 216.339s-.897.879-2.589-.479a5.444 5.444 0 0 1-.164-1.363c.864.658 2.466 1.842 2.753 1.842zm5.25.077a.67.67 0 0 1-.519.132.672.672 0 0 1-.452-.287c.348-.266.669-.566.956-.897.009.359.015.717.015 1.052zm7.017.616c0 .371 0 .715.033.996.108 1.333 3.382.828 3.382.828 2.989.419 4.066-1.017 4.421-2.392a5.728 5.728 0 0 0 .111-2.182v-.272a2.024 2.024 0 0 0-1.862.379c-1.331 1.08-3.092.251-3.092.251s-1.294.111-1.794.542c-.281.242-.744-.341-1.094-.897a61.426 61.426 0 0 0-.078 1.701c-.024.35-.03.708-.027 1.046z"
        opacity=".05"
      />
      <path
        fill="#FF4842"
        d="M306.887 216.949s.897.879 2.587-.479c.112-.444.169-.901.167-1.36-.867.655-2.47 1.839-2.754 1.839zm-5.25.083a.67.67 0 0 0 .969-.155 6.35 6.35 0 0 1-.957-.897c-.009.356-.014.714-.012 1.052zm17.977-110.763c.063-.138.123-.275.18-.41.057-.134.077-.209.11-.317-.11.237-.206.48-.29.727z"
        opacity=".05"
      />
      <path
        fill="#005249"
        d="M157.592 279.461a4.114 4.114 0 0 1-.917-3.131l13.196-107.979 1.094-8.97a4.142 4.142 0 0 1 4.078-3.635l38.654-.257a4.135 4.135 0 0 0 4.108-4.249l-.015-.409a4.14 4.14 0 0 1 4.135-4.279h40.619a4.134 4.134 0 0 1 4.098 4.682 4.137 4.137 0 0 0 3.995 4.682l39.01.996a4.13 4.13 0 0 1 3.97 4.831l-19.502 113.909-.368 2.152a4.042 4.042 0 0 1-.598 1.543l-135.557.114z"
      />
      <path fill="#FFF" d="m275.315 123.676 18.793 12.983-44.068 63.784-18.792-12.983z" />
      <path fill="#FFC107" d="m276.669 126.698 13.24 9.204-9.203 13.241-13.241-9.204z" />
      <path fill="#FFC107" d="m266.296 142.655 13.285 9.178-9.178 13.285-13.285-9.178z" opacity=".5" />
      <path fill="#FFC107" d="m255.212 158.694 13.285 9.179-9.178 13.285-13.286-9.179z" opacity=".3" />
      <path fill="#FFF" d="m248.763 119.681 21.925 6.407-21.664 74.133-21.925-6.407z" />
      <path fill="#FF4842" d="m251.642 121.821 15.5 4.53-4.53 15.499-15.5-4.53z" />
      <path fill="#FF4842" d="m246.173 140.533 15.5 4.53-4.53 15.499-15.5-4.53z" opacity=".5" />
      <path fill="#FF4842" d="m240.705 159.242 15.5 4.53-4.53 15.499-15.5-4.53z" opacity=".3" />
      <path fill="#FFF" d="m233.233 120.85 22.768 1.832-6.194 76.985-22.768-1.832z" />
      <path fill="#1890FF" d="m236.474 122.364 16.096 1.295-1.295 16.096-16.096-1.295z" />
      <path fill="#1890FF" d="m234.908 141.792 16.095 1.295-1.295 16.096-16.095-1.295z" opacity=".5" />
      <path fill="#1890FF" d="m233.346 161.225 16.095 1.295-1.295 16.096-16.095-1.295z" opacity=".3" />
      <path fill="#FFF" d="m222.99 123.945 22.818-1.04 3.517 77.153-22.818 1.04z" />
      <path fill="#00AB55" d="m226.392 125.036 16.13-.735.736 16.13-16.13.736z" />
      <path fill="#00AB55" d="m227.912 143.893 16.148-.807.807 16.147-16.148.807z" opacity=".5" />
      <path fill="#00AB55" d="m228.166 163.985 16.13-.735.736 16.13-16.13.736z" opacity=".3" />
      <path
        fill="#F4F6F8"
        d="m232.679 225.726-20.294 7.851-29.661 11.466c-1.121-2.093-2.771-4.921-4.813-8.297-7.026-11.642-18.65-29.75-29.001-45.665-11.66-17.938-21.696-33.075-21.696-33.075l7.265-2.093 59.346-17.23 38.854 87.043z"
      />
      <path d="m232.679 225.726-20.294 7.851a152.535 152.535 0 0 1-34.484 3.169c-7.026-11.642-18.65-29.75-29-45.665l-14.42-35.18 59.343-17.218 38.855 87.043z" opacity=".1" />
      <path fill="#00AB55" d="M247.777 220.841s-31.526 18.65-78.596 14.432l-37.525-91.486 17.984-3.091 62.168-10.677 35.279 89.043.69 1.779z" />
      <path
        fill="#FF4842"
        d="M247.075 219.074c-7.424.365-16.013.329-24.654-.702-9.351-1.101-18.757-3.367-26.82-7.523-6.742-3.471-12.506-7.247-17.532-11.776-13.074-11.759-21.164-28.579-28.429-58.365l62.168-10.677 35.267 89.043z"
        opacity=".1"
      />
      <path
        fill="#FFF"
        d="M270.203 213.959s-23.239 4.55-46.894 1.749c-9.351-1.1-18.757-3.367-26.82-7.522-6.742-3.471-12.503-7.247-17.532-11.777-16.181-14.557-24.725-36.866-33.536-81.918 0 0 38.633 9.325 76.836-11.101-.012.015 12.865 80.373 47.946 110.569z"
      />
      <path
        fill="#00AB55"
        d="M165.405 131.03s31.416-1.776 40.187-8.548l1.331 4.276s-20.758 9.157-41.518 9.268v-4.996zm0 21.107s30.417-1.665 53.399-12.766l.777 1.776s-18.237 10.647-54.176 13.433v-2.443zm2.448 8.225s30.418-1.665 53.397-12.766l.777 1.776s-18.237 10.643-54.174 13.433v-2.443zm2.446 8.237s30.417-1.666 53.399-12.766l.774 1.775s-18.237 10.647-54.173 13.433v-2.442zm2.449 8.236s30.417-1.665 53.396-12.766l.777 1.776s-18.237 10.647-54.173 13.433v-2.443zm2.445 8.24s30.417-1.665 53.399-12.766l.774 1.776s-18.237 10.643-54.173 13.433v-2.443z"
        opacity=".3"
      />
      <path
        fill="#00AB55"
        d="M389.069 287.344s-12.641-.762-11.152 8.097c0 0-.299 1.563 1.124 2.275 0 0 .021-.658 1.295-.434.454.077.916.099 1.375.066a2.832 2.832 0 0 0 1.677-.694c.468-.409 3.555-1.468 4.936-7.274 0 0 1.023-1.267.981-1.593l-2.132.897s.73 1.54.156 2.816c0 0-.069-2.759-.479-2.691-.083 0-1.109.533-1.109.533s1.253 2.69.299 4.628c0 0 .359-3.304-.699-4.434l-1.495.876s1.465 2.768.472 5.029c0 0 .254-3.465-.789-4.817l-1.361 1.062s1.379 2.729.539 4.604c0 0-.111-4.036-.835-4.341 0 0-1.195 1.049-1.369 1.494 0 0 .942 1.98.356 3.026 0 0-.359-2.691-.652-2.691 0 0-1.196 1.794-1.309 2.99 0 0 .051-1.818 1.022-3.172a3.593 3.593 0 0 0-1.818.942s.186-1.262 2.111-1.37c0 0 .981-1.351 1.241-1.435 0 0-1.914-.158-3.074.356 0 0 1.023-1.196 3.427-.649l1.342-1.094s-2.52-.347-3.588.036c0 0 1.229-1.052 3.95-.299l1.462-.873s-2.147-.463-3.426-.299c0 0 1.348-.729 3.856.06l1.044-.47s-1.573-.299-2.033-.358c-.461-.06-.488-.174-.488-.174a5.426 5.426 0 0 1 2.957.329s2.222-.813 2.186-.954z"
      />
      <ellipse cx="380.363" cy="298.487" fill="#00AB55" opacity=".1" rx="8.945" ry="1.513" />
      <path
        fill="#00AB55"
        d="M394.956 258.102s-7.125-.43-6.279 4.565a1.218 1.218 0 0 0 .631 1.283s.015-.371.733-.245c.255.042.515.054.774.036.349-.023.681-.159.945-.389.264-.23 2.003-.828 2.783-4.102 0 0 .577-.714.553-.897l-1.196.511s.41.87.087 1.591c0 0-.039-1.558-.269-1.522-.048 0-.625.299-.625.299s.706 1.495.173 2.61c0 0 .204-1.862-.394-2.502l-.846.496s.825 1.561.266 2.834c0 0 .143-1.955-.446-2.714l-.765.598s.774 1.539.299 2.595c0 0-.063-2.275-.469-2.446a4.75 4.75 0 0 0-.775.834s.532 1.118.204 1.707c0 0-.204-1.515-.368-1.521 0 0-.67 1.001-.739 1.689a3.671 3.671 0 0 1 .577-1.794 2.007 2.007 0 0 0-1.025.532s.104-.711 1.196-.771c0 0 .553-.763.699-.81 0 0-1.079-.09-1.734.2 0 0 .577-.67 1.932-.365l.759-.619s-1.423-.194-2.024.021c0 0 .694-.598 2.227-.161l.826-.494a7.445 7.445 0 0 0-1.935-.164s.763-.413 2.174.033l.598-.263s-.897-.177-1.148-.203c-.252-.027-.275-.099-.275-.099a3.078 3.078 0 0 1 1.668.185s1.232-.46 1.208-.538z"
      />
      <ellipse cx="390.052" cy="264.383" fill="#00AB55" opacity=".1" rx="5.044" ry="1" />
      <path
        fill="#00AB55"
        d="M352.896 262.437s-8.604-.52-7.588 5.51a1.47 1.47 0 0 0 .763 1.549s0-.449.897-.299c.309.052.623.067.935.045a1.923 1.923 0 0 0 1.142-.473c.318-.279 2.419-.998 3.361-4.951 0 0 .697-.861.667-1.085l-1.468.622s.496 1.046.104 1.916c0 0-.048-1.877-.326-1.835-.056 0-.753.364-.753.364s.852 1.824.209 3.152c0 0 .245-2.249-.475-3.02l-1.023.598s.996 1.886.32 3.423c0 0 .174-2.359-.535-3.289l-.927.724s.939 1.857.365 3.133c0 0-.072-2.747-.565-2.954 0 0-.81.715-.933 1.008 0 0 .64 1.348.242 2.06 0 0-.245-1.83-.445-1.839 0 0-.804 1.214-.897 2.042.04-.768.28-1.512.697-2.159a2.427 2.427 0 0 0-1.235.643s.125-.858 1.435-.933c0 0 .667-.92.846-.974 0 0-1.303-.111-2.093.239 0 0 .694-.807 2.329-.44l.915-.747s-1.713-.233-2.442.024c0 0 .837-.715 2.69-.191l.996-.598s-1.462-.314-2.335-.201c0 0 .921-.496 2.625.042l.711-.32s-1.07-.209-1.384-.242c-.314-.033-.329-.119-.329-.119a3.69 3.69 0 0 1 2.012.221s1.519-.553 1.492-.646z"
      />
      <ellipse cx="346.97" cy="270.022" fill="#00AB55" opacity=".1" rx="6.09" ry="1.028" />
      <rect width="51.026" height="91.312" x="303.926" y="69.211" fill="#FFF" rx="4.737" transform="rotate(-71.99 329.44 114.867)" />
      <circle cx="303.613" cy="103.507" r="9.376" fill="#00AB55" opacity=".2" />
      <path
        fill="#00AB55"
        d="m326.046 101.82.794-2.443 38.847 12.63-.794 2.442zM324.02 108.046l.794-2.443 19.79 6.434-.795 2.442zM321.993 114.277l.794-2.442 32.006 10.405-.794 2.443zM319.969 120.506l.794-2.443 38.847 12.63-.794 2.442z"
        opacity=".2"
      />
      <path fill="#00AB55" d="m344.401 140.914 1.536-4.723 10.75 3.495-1.535 4.723z" opacity=".5" />
      <rect width="50.3" height="78.305" x="291.549" y="174.486" fill="#FFF" rx="4" transform="rotate(-57.265 316.7 213.638)" />
      <path
        fill="#DFE3E8"
        d="m279.925 210.671 7.632-12.012 2.826 1.796-7.632 12.012zM288.959 213.104l16.76-26.38 2.592 1.646-16.76 26.38zM294.495 216.618l16.76-26.38 2.592 1.646-16.76 26.38zM300.03 220.138l16.76-26.38 2.592 1.646-16.76 26.38zM305.757 225.238l16.877-26.254 2.58 1.658-16.878 26.254z"
      />
      <path fill="#F4F6F8" d="m311.101 227.17 16.76-26.38 2.592 1.647-16.76 26.38zM316.637 230.685l16.76-26.38 2.592 1.646-16.76 26.38z" />
      <path fill="#DFE3E8" d="m339.879 215.7 4.34-6.831 6.831 4.34-4.34 6.83z" />
      <path
        fill="#00AB55"
        d="m269.277 168.76-45.767.493a3.127 3.127 0 0 0-3.094 3.125v3.782a3.127 3.127 0 0 1-3.05 3.124l-33.024.792a3.124 3.124 0 0 1-3.095-2.308l-1.551-5.701a3.124 3.124 0 0 0-3.053-2.308l-44.113.475a3.125 3.125 0 0 0-3.05 3.648l17.747 104.449a3.129 3.129 0 0 0 3.071 2.604l139.363.598a3.129 3.129 0 0 0 3.082-3.714l-20.366-106.521a3.125 3.125 0 0 0-3.1-2.538z"
      />
      <path
        fill="#007B55"
        d="m269.277 168.76-45.767.493a3.127 3.127 0 0 0-3.094 3.125v3.782a3.127 3.127 0 0 1-3.05 3.124l-33.024.792a3.124 3.124 0 0 1-3.095-2.308l-1.551-5.701a3.124 3.124 0 0 0-3.053-2.308l-44.113.475a3.125 3.125 0 0 0-3.05 3.648l17.747 104.449a3.129 3.129 0 0 0 3.071 2.604l139.363.598a3.129 3.129 0 0 0 3.082-3.714l-20.366-106.521a3.125 3.125 0 0 0-3.1-2.538z"
        opacity=".243"
      />
      <path
        fill="url(#b)"
        d="m269.277 168.76-45.767.493a3.127 3.127 0 0 0-3.094 3.125v3.782a3.127 3.127 0 0 1-3.05 3.124l-33.024.792a3.124 3.124 0 0 1-3.095-2.308l-1.551-5.701a3.124 3.124 0 0 0-3.053-2.308l-44.113.475a3.125 3.125 0 0 0-3.05 3.648l17.747 104.449a3.129 3.129 0 0 0 3.071 2.604l139.363.598a3.129 3.129 0 0 0 3.082-3.714l-20.366-106.521a3.125 3.125 0 0 0-3.1-2.538z"
        opacity=".32"
      />
      <ellipse cx="119.593" cy="258.664" fill="#00AB55" opacity=".1" rx="4.846" ry="1" />
      <ellipse cx="101.03" cy="260.545" fill="#00AB55" opacity=".1" rx="4.846" ry="1" />
      <ellipse cx="108.459" cy="265.905" fill="#00AB55" opacity=".1" rx="3.444" ry="1" />
      <ellipse cx="89.193" cy="265.433" fill="#00AB55" opacity=".1" rx="3.444" ry="1" />
      <path
        fill="#00AB55"
        d="M100.925 260.398s6.796-22.279-1.064-31.204c-5.881-6.676-12.557-5.877-15.547-5.052a5.528 5.528 0 0 0-3.564 2.963c-1.046 2.254-.858 5.913 6.521 10.186 12.35 7.151 13.119 16.96 13.119 16.96l.535 6.147z"
      />
      <path
        stroke="#005249"
        stroke-linecap="round"
        stroke-width=".5"
        d="M85.382 227.302s20.031 6.278 15.546 33.096m-3.845-30.851s-2.76 1.86-1.88 4.655m-7.813-2.999s1.687-1.716 3.741-.768m4.179 9.397s2.963-1.495 3.728.379m3.6 2.751s-2.224-.176-2.263.897"
      />
      <path fill="#00AB55" d="M101.2 260.575s-7.961-16.193-10.147-15.846c-1.037.17-1.516 1.196-1.734 2.218a6.516 6.516 0 0 0 .434 3.941c1.13 2.601 4.165 7.519 11.447 9.687z" />
      <path
        stroke="#005249"
        stroke-linecap="round"
        stroke-width=".5"
        d="M91.113 247.808s8.353 12.115 9.968 12.647M90.344 249.701h1.949M92.68 254.132l3.058.131M94.542 250.586l-.332 1.593M97.792 255.432l-.06 1.447"
      />
      <path fill="#00AB55" d="M100.964 260.575s7.959-16.193 10.144-15.846c1.041.17 1.519 1.196 1.734 2.218a6.514 6.514 0 0 1-.433 3.941c-1.13 2.601-4.168 7.519-11.445 9.687z" />
      <path
        stroke="#005249"
        stroke-linecap="round"
        stroke-width=".5"
        d="M111.051 247.808s-8.371 12.115-9.97 12.647M111.82 249.701h-1.949M109.485 254.132l-3.059.131M107.622 250.586l.332 1.593M104.372 255.432l.06 1.447"
      />
      <path
        fill="#00AB55"
        d="M87.295 264.357a1.375 1.375 0 0 1-.452-.685.599.599 0 0 1 .317-.697c.3-.11.598.09.837.299.24.21.512.431.81.38a1.237 1.237 0 0 1-.382-1.16.47.47 0 0 1 .105-.236c.161-.174.454-.099.648.039.598.43.787 1.264.79 2.018.03-.277.03-.555 0-.831a.853.853 0 0 1 .334-.727.938.938 0 0 1 .476-.114.988.988 0 0 1 .774.222.767.767 0 0 1-.03.947c-.229.257-.5.474-.801.64a1.805 1.805 0 0 0-.571.547.399.399 0 0 0-.042.096h-1.74a4.826 4.826 0 0 1-1.073-.738zm30.331-6.524a1.355 1.355 0 0 1-.452-.682.599.599 0 0 1 .314-.696c.299-.111.598.089.837.299.239.209.509.436.817.391a1.236 1.236 0 0 1-.386-1.157.472.472 0 0 1 .107-.236c.162-.173.455-.099.649.036.613.433.787 1.268.79 2.021a4.008 4.008 0 0 0 0-.834.852.852 0 0 1 .299-.736.955.955 0 0 1 .475-.11.998.998 0 0 1 .774.218.768.768 0 0 1-.033.951 2.996 2.996 0 0 1-.798.64 1.767 1.767 0 0 0-.571.544.499.499 0 0 0-.042.098h-1.701a4.78 4.78 0 0 1-1.079-.747zm-10.108 6.524a1.406 1.406 0 0 1-.455-.685.6.6 0 0 1 .317-.697c.299-.11.598.09.837.299.239.21.505.437.816.395a1.247 1.247 0 0 1-.385-1.16.472.472 0 0 1 .107-.236c.162-.174.455-.099.649.039.613.43.783 1.264.789 2.018.03-.277.03-.555 0-.832a.855.855 0 0 1 .314-.735.93.93 0 0 1 .476-.114.988.988 0 0 1 .774.222.764.764 0 0 1-.033.947 3.028 3.028 0 0 1-.798.64 1.798 1.798 0 0 0-.571.547.411.411 0 0 0-.042.096h-1.734c-.385-.2-.742-.45-1.061-.744z"
      />
      <circle cx="84.467" cy="87.003" r="6.467" fill="#00AB55" opacity=".1" />
      <circle cx="395.425" cy="138.681" r="6.467" fill="#00AB55" opacity=".1" />
      <circle cx="279.178" cy="66.467" r="6.467" fill="#00AB55" opacity=".1" />
      <circle cx="97.4" cy="122.68" r="10.838" fill="#00AB55" opacity=".1" />
      <path fill="#007B55" d="M206.029 209.911c-7.975 0-14.44 6.465-14.44 14.44s6.465 14.44 14.44 14.44 14.44-6.465 14.44-14.44-6.465-14.44-14.44-14.44z" />
      <path
        fill="#00AB55"
        d="M206.029 211.59c7.047 0 12.761 5.714 12.761 12.761 0 7.048-5.714 12.761-12.761 12.761-7.048 0-12.761-5.713-12.761-12.761.006-7.045 5.716-12.754 12.761-12.761"
        opacity=".72"
      />
      <path
        fill="#007B55"
        d="m200.058 225.406-.699-.681a.721.721 0 0 1 0-1.065l6.127-5.984a.753.753 0 0 1 .546-.231c.206 0 .404.083.545.231l6.127 5.978a.733.733 0 0 1 0 1.065l-.699.682a.768.768 0 0 1-1.091 0l-3.622-3.727v8.843a.725.725 0 0 1-.219.523.76.76 0 0 1-.534.217h-1.009a.75.75 0 0 1-.759-.74v-8.832l-3.622 3.726a.768.768 0 0 1-1.091-.005z"
      />
    </svg>
  );
};

import { get, post, patch, deleted } from "../index";
import Category from "./Category.Routes";

const CategoryServices = {
  getCategories: async () => {
    const data = await get(Category.getCategories);
    return data;
  },
  createCategories: async (obj) => {
    const data = await post(Category.createCategories, obj);
    return data;
  },
  updateCategories: async (id, obj) => {
    const data = await post(Category.updateCategories + `${id}`, obj);
    return data;
  },
  deleteCategory: async (id) => {
    const data = await deleted(Category.deleteCategories + `${id}`);
    return data;
  },
};

export default CategoryServices;

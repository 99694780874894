import { Button, CircularProgress, Grid, IconButton, InputAdornment, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import colors from "../../style/colors";
import Image from "../../assets/Images";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { SuccessToaster, ErrorToaster } from "../../components/Toaster/Toaster";
import AuthField from "../../components/TextField/AuthField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthServices from "../../apis/Auth/AuthServices";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles({
  bgImage: {
    backgroundImage: `url(${Image.bgImage})`,
    backgroundPosition: "center",
    // backgroundPosition: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // width: "100%",
    // height: "100vh",
    opacity: 0.8,
  },
  signUp: {
    textDecoration: "none",
    color: colors.primary,
    marginLeft: "6px",
  },
});

export const Login = () => {
  const { userLogin } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  // *For Show Password Toggle
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLogin = async (formData) => {
    setLoading(true);
    try {
      const obj = {
        email: formData.email,
        password: formData.password,
      };
      const { status, responseCode, data } = await AuthServices.login(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster("Login Successful");
        localStorage.setItem("token", data.token);
        userLogin(data);
        navigate("/");
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid
      container
      className={classes.bgImage}
      sx={{
        height: "100vh",
        bgcolor: colors.lowPurple,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item xs={10}>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={11}
            sm={8}
            md={5}
            sx={{
              bgcolor: `rgb(255 255 255 / 0.9)`,
              px: 4,
              py: 3,
              borderRadius: "16px",
              boxShadow: `rgba(0, 0, 0, 0.08) 0px 6px 30px`,
            }}
          >
            <Typography component="form" onSubmit={handleSubmit(onLogin)}>
              <Grid container>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <img src={Image.logo} alt="logoImg" style={{ m: 1, width: "160px" }} />
                </Grid>
                <Grid item xs={12} sx={{ py: { xs: 2, sm: 2, md: 2 }, px: { xs: 0, sm: 2, md: 2 } }}>
                  <Typography
                    variant="h6"
                    sx={{
                      pt: 2,
                      fontSize: "16px",
                      color: colors.secondary,
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </Typography>
                  <Typography sx={{ color: colors.lightFont }}>Enter your credentials to continue</Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: { xs: 2, sm: 2, md: 2 }, px: { xs: 0, sm: 2, md: 2 } }}>
                  <AuthField
                    label="Email"
                    type="email"
                    error={errors?.email?.message}
                    register={register("email", {
                      required: "Please enter email.",
                      pattern: {
                        message: "Please enter email.",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sx={{ py: { xs: 2, sm: 2, md: 2 }, px: { xs: 0, sm: 2, md: 2 } }}>
                  <AuthField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    error={errors?.password?.message}
                    register={register("password", {
                      required: "Please enter password.",
                      minLength: {
                        value: 6,
                        message: "Password must have at least 6 characters.",
                      },
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ py: { xs: 2, sm: 2, md: 2 }, px: { xs: 0, sm: 2, md: 2 } }}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      py: 1.5,
                      width: "100%",
                      textTransform: "capitalize",
                      fontSize: 14,
                      fontWeight: "bold",
                      borderRadius: "10px",
                      backgroundColor: colors.primary,
                      "&:hover": {
                        backgroundColor: colors.primary,
                      },
                    }}
                  >
                    {loading ? <CircularProgress color="inherit" size={24} /> : "Login"}
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
                  <Typography variant="body2">
                    Powered by{" "}
                    <span style={{ fontSize: "14px", paddingLeft: "5px" }}>
                      <Link> Mangotech Solutions </Link>
                    </span>
                  </Typography>
                  {/* <Typography variant="body1">
                  <Link sx={{ p: 1, color: colors.ufoGreen }}>
                    Forget Password?
                  </Link>
                </Typography> */}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Avatar, Box, Button, Checkbox, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TextField, Tooltip, Typography } from "@mui/material";
import { Add, UploadFile, Delete, AddBox, CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import colors from "../../style/colors";
import InputField from "../../components/TextField/InputField";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import AddClientDialog from "../../components/Dialog/ClientDialog/AddClientDialog";
import OrderField from "../../components/TextField/OrderField";
import SelectField from "../../components/SelectField/SelectField";
import ClientsServices from "../../apis/Clients/ClientsServices";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/Toaster";
import CategoryServices from "../../apis/Category/CategoryServices";
import OrdersServices from "../../apis/Orders/OrdersServices";
import { imageBaseUrl } from "../../utils";
import { Loading } from "../../components/UI/Loader";
import { useNavigate } from "react-router-dom";
import { currentDate } from "../../utils/DateFormate";
import { useForm } from "react-hook-form";

const CreateOrder = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
  } = useForm();
  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [imgLoad, setImgLoad] = useState(false);

  //* For Add Client Dialog
  const [clientDialog, setClientDialog] = useState(false);

  const [clientsData, setClientsData] = useState([]);
  const [isNewClientAdded, setIsNewClientAdded] = useState(false);
  console.log("🚀 ~ file: CreateOrder.js:38 ~ CreateOrder ~ isNewClientAdded:", isNewClientAdded);

  const [clientId, setClientId] = useState({ label: "", value: "" });

  const [categoryData, setCategoryData] = useState([]);

  const [orderDate, setOrderDate] = useState(currentDate);
  // eslint-disable-next-line no-unused-vars
  const [deliveryDate, setDeliveryDate] = useState("");

  const [notes, setNotes] = useState("");

  // *For Image
  // const [image, setImage] = useState();

  // const handleImageChange = (event) => {
  //   const data = URL.createObjectURL(event.target.files[0]);
  //   setImage(data);
  // };

  // *For Select Invoice
  // eslint-disable-next-line no-unused-vars
  const [selectedCurrency, setSelectedCurrency] = useState("AED");

  // *For Products list
  const [products, setProducts] = useState([{ id: uuidv4() }]);

  // *For Invoice Summary
  const [subTotal, setSubTotal] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(0);

  // *For Handle Select Open
  const [openSelect, setOpenSelect] = useState();

  // *For Update Product
  const handleUpdateProduct = (value, id, key) => {
    try {
      const index = products.findIndex((e) => e.id === id);
      let updateProduct = [...products];
      updateProduct[index][key] = value;
      console.log("🚀 ~ file: CreateOrder.js:78 ~ handleUpdateProduct ~ updateProduct:", updateProduct);

      updateProduct.forEach((element) => {
        if (element.hasOwnProperty("size") === false) {
          element["size"] = null;
        }
        if (element.hasOwnProperty("quantity") === false) {
          element["quantity"] = 1;
        }
        if (element.hasOwnProperty("image") === false) {
          element["image"] = null;
        }
      });

      setProducts(updateProduct);
    } catch (error) {
      console.log("file: index.js:64 => handleUpdateProduct => error", error);
    }
  };

  // *For Add Product
  const addProduct = () => {
    try {
      // if (products[products.length - 1]?.image) {
      //   const updateProduct = [...products];
      //   let obj = { id: uuidv4() };
      //   updateProduct.push(obj);
      //   setProducts(updateProduct);
      // } else {
      //   ErrorToaster("Please upload the image.");
      // }
      const updateProduct = [...products];
      let obj = { id: uuidv4() };
      updateProduct.push(obj);
      setProducts(updateProduct);
    } catch (error) {
      console.log("file: index.js:79 => addProduct => error", error);
    }
  };

  // *For Delete Product
  const deleteProduct = (id) => {
    try {
      const updateProduct = products.filter((product) => product.id !== id);
      setProducts(updateProduct);
    } catch (error) {
      console.log("file: index.js:98 => deleteProduct => error", error);
    }
  };

  //* API FOR GET ORDER NUMBER
  const getOrderNo = async () => {
    try {
      const { data } = await OrdersServices.orderNo();
      if (data === null) return;
      setOrderNumber(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //* API FOR GET ALL CLIENTS
  const getClients = async (istrue) => {
    try {
      const { data } = await ClientsServices.getClients();
      if (data === null) return;
      setClientsData(
        data?.filter((item) => {
          return item?.is_active !== "0";
        })
      );
      if (istrue) {
        setIsNewClientAdded(true);
      }

      // setLastClient(data[data?.length - 1].name);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* API FOR GET ALL CATEGORIES
  const getCategories = async () => {
    try {
      const { data } = await CategoryServices.getCategories();
      if (data === null) return;
      setCategoryData(data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* API FOR CREATE ORDERS
  const createOrders = async () => {
    try {
      const newProducts = products.map((element) => ({ ...element, row_total: (element.quantity * element.unit_price).toString() }));
      if (deliveryDate) {
        const obj = {
          order_no: orderNumber,
          client_id: isNewClientAdded ? clientsData[clientsData?.length - 1]?.id : clientId?.value,
          order_date: orderDate,
          delivery_date: deliveryDate,
          sub_total: subTotal,
          vat: 5,
          total: total,
          advance_in_paid: advanceAmount ? advanceAmount : 0,
          note: notes ? notes : "",
          balance: (Number(total) - Number(advanceAmount)).toFixed(2),
          orderdetails: newProducts,
        };

        const { data } = await OrdersServices.createOrders(obj);
        if (data === null) return;
        SuccessToaster("Order Created Successfully");
        navigate("/orderlist");
      } else {
        ErrorToaster("Fill All Date Fields");
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* IMAGE FOR CREATE ORDER
  const orderPicture = async (image, id, key) => {
    try {
      setImgLoad(true);
      let formData = new FormData();
      formData.append("image", image);
      const { data } = await OrdersServices.imageOrder(formData);
      if (data === null) return;
      handleUpdateProduct(data, id, key);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setImgLoad(false);
    }
  };

  useEffect(() => {
    let subTotal = 0;
    let total = 0;
    products.forEach((element) => {
      let st = (element?.quantity ?? 0) * (element?.unit_price ?? 0);
      // let t = ((element?.tax ?? 0) / 100) * st;
      let t = (5 / 100) * st;
      let gt = st + t;
      subTotal += st;
      total += gt;
    });
    setSubTotal(Number(subTotal).toFixed(2));
    setTotal(Number(total).toFixed(2));
  }, [products]);

  useEffect(() => {
    getOrderNo();
    getClients();
    getCategories();
  }, []);

  return (
    <Fragment>
      <AddClientDialog open={clientDialog} onClose={() => setClientDialog(false)} action={() => getClients(true)} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          borderRadius: "16px",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 0.5 }}>
          <AddBox sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            Create Order
          </Typography>
        </Box>
        <BreadCrumbs currentPage={["Orders", "Create Order"]} />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", pl: 16, py: 4 }}>
          <Loading />
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{
              my: 2,
              bgcolor: colors.white,
              p: 3,
              borderRadius: 3,
              boxShadow: "rgb(145 158 171 / 16%) 0px 4px 8px 0px",
            }}
          >
            <Grid container spacing={1} sx={{ my: 1 }}>
              <Grid item md={3} xs={3.4}>
                <InputField disable defaultValue={orderNumber} size={"small"} type={"number"} label={"Order No."} />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={4} xs={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    fullWidth
                    id="user-tags"
                    sx={{ "& fieldset": { borderRadius: 2.2 } }}
                    options={clientsData?.map((option) => ({ label: option.name, value: option.id }))}
                    value={isNewClientAdded ? clientsData[clientsData?.length - 1]?.name : clientId?.label}
                    onChange={(event, value) => {
                      console.log(value);
                      setClientId(value);
                      setIsNewClientAdded(false);
                    }}
                    renderInput={(params) => <TextField {...params} label="Select User" placeholder="Client Name" />}
                  />
                </Box>
              </Grid>
              <Grid item md={2} xs={4} sx={{ display: "flex", alignItems: "center" }}>
                <Box mb={0}>
                  <Button
                    onClick={() => {
                      setClientDialog(!clientDialog);
                    }}
                    sx={{ bgcolor: colors.iconBg }}
                    startIcon={<Add />}
                  >
                    <Typography variant="body2" p={0.5}>
                      Add Client
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2.5,
                      },
                    }}
                  >
                    <TextField
                      defaultValue={currentDate()}
                      label="Order Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      onChange={(e) => setOrderDate(e.target.value)}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2.5,
                      },
                    }}
                  >
                    <TextField
                      label="Delivery Date"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      error={errors?.delivery?.message && true}
                      helperText={errors?.delivery?.message}
                      onChange={(e) => setDeliveryDate(e.target.value)}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            {/* ========== Product ========== */}
            <Box
              sx={{
                mt: 4,
                mb: 1,
                width: "100%",
                overflow: "auto",
                overflowX: "scroll",
                "&::-webkit-scrollbar": {
                  bgcolor: colors.white,
                  height: "6px",
                  borderRadius: "12px",
                  cursor: "pointer",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: colors.primary,
                  borderRadius: "14px",
                  cursor: "pointer",
                },
              }}
            >
              <Table stickyHeader sx={{ minWidth: 600 }}>
                <Box sx={{ bgcolor: colors.bgColor, mb: 1, p: 1, borderRadius: 2 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Category<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={1.8} sm={2} md={2} lg={2} xl={2}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Product Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Size
                      </Typography>
                    </Grid>
                    <Grid item xs={1.8} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Quantity <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Unit Price <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={1.3} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        Image
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                      <Typography variant="body1" sx={{ fontWeight: 700, textAlign: "center" }}>
                        Sub Total
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {products.length > 0 &&
                  products.map((item, index) => (
                    <Grid key={index} container spacing={1} alignItems={"center"}>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel>Category</InputLabel>
                          <Select
                            label="Category"
                            multiple
                            open={openSelect === index}
                            onClose={() => setOpenSelect(false)}
                            onOpen={() => setOpenSelect(index)}
                            value={item?.category_id ? [item?.category_id] : []}
                            onChange={(e) => handleUpdateProduct(e.target.value.pop(), item?.id, "category_id")}
                            renderValue={(selected) => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map((value, index) => (
                                  <Typography key={index}>{categoryData?.find((e) => e.id === value)?.name} </Typography>
                                ))}
                              </Box>
                            )}
                            sx={{
                              textAlign: "left",
                              mb: 2,
                              borderRadius: 2,
                            }}
                          >
                            {categoryData.map((item, i) => (
                              <MenuItem key={i} value={item.id} onClick={() => setOpenSelect(false)}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1.8} sm={2} md={2} lg={2} xl={2}>
                        <OrderField label={"Product Name"} value={item?.product_name} onChange={(e) => handleUpdateProduct(e.target.value, item.id, "product_name")} />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <OrderField size={"small"} label={"Size"} value={item?.size} onChange={(e) => handleUpdateProduct(e.target.value, item.id, "size")} />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <OrderField
                          size={"small"}
                          type={"number"}
                          label={"Quantity"}
                          defaultValue={item?.quantity ? item?.quantity : 1}
                          onChange={(e) => handleUpdateProduct(e.target.value, item.id, "quantity")}
                        />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <OrderField size={"small"} type={"number"} label={"Unit Price"} value={item?.unit_price} onChange={(e) => handleUpdateProduct(e.target.value, item.id, "unit_price")} />
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {item.image ? (
                          <Box sx={{ position: "relative", display: "flex", justifyContent: "center" }}>
                            <Avatar src={imageBaseUrl + item.image} sx={{ minWidth: "50px", height: "50px" }} />
                            <IconButton
                              sx={{ position: "absolute", display: "flex", justifyContent: "center", width: 30, height: 30, borderRadius: 40, left: 35, top: 0 }}
                              onClick={() => handleUpdateProduct("", item.id, "image")}
                            >
                              <Tooltip title="delete">
                                <Delete sx={{ fontSize: "22px", color: colors.opal }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        ) : (
                          <Fragment>
                            {imgLoad ? (
                              <Box>Uploading...</Box>
                            ) : (
                              <Box>
                                <span
                                  className="btn btn-primary btn-file"
                                  style={{
                                    position: "relative",
                                    overflow: "hidden",
                                    color: "white",
                                    height: "50px",
                                    width: "fit-content",
                                    padding: "3px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton>
                                    <UploadFile sx={{ fontSize: "28px", color: colors.primBlue }} />
                                  </IconButton>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    style={{
                                      position: " absolute",
                                      filter: "alpha(opacity=0)",
                                      top: "0",
                                      right: "0",
                                      align: "right",
                                      minWidth: "100%",
                                      minHeight: "100%",
                                      fontSize: " 100px",
                                      opacity: "0",
                                      outline: "none",
                                      background: "white",
                                      cursor: "inherit",
                                      display: "block",
                                    }}
                                    onChange={(event) => {
                                      orderPicture(event.target.files[0], item.id, "image");
                                      // handleImageChange(event);
                                    }}
                                  />
                                </span>
                              </Box>
                            )}
                          </Fragment>
                        )}
                      </Grid>
                      <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
                        <Typography variant="body1" sx={{ textAlign: "center" }}>
                          {selectedCurrency} {((item?.unit_price ?? 0) * (item?.quantity ?? 0)).toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} xl={0.5}>
                        {products.length > 1 && (
                          <Box
                            sx={{
                              display: "flex",
                              bgcolor: colors.flashWhite,
                              borderRadius: 4,
                              width: "fit-content",
                              px: 1,
                              py: 0.5,
                            }}
                          >
                            <IconButton disableRipple aria-label="delete" onClick={() => deleteProduct(item.id)}>
                              <Delete />
                            </IconButton>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                <Box sx={{ my: 1 }}>
                  <Button sx={{ bgcolor: colors.iconBg }} startIcon={<Add />} onClick={() => addProduct()}>
                    <Typography variant="body2" p={0.5}>
                      Add New Item
                    </Typography>
                  </Button>
                </Box>
              </Table>
            </Box>

            {/* ========== Invoice Summary ========== */}
            <Grid container spacing={1} sx={{ display: "flex", justifyContent: "space-between", pt: 4 }}>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  label="Additional Notes"
                  multiline
                  rows={4}
                  fullWidth
                  InputProps={{
                    style: { borderRadius: 8 },
                  }}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={8}>
                <Box sx={{ bgcolor: colors.bgColor, p: 1, borderRadius: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Summary
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">SubTotal</Typography>
                  <Typography variant="body1">
                    {selectedCurrency} {subTotal}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Vat</Typography>
                  <Typography variant="body1">{selectedCurrency} 5%</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Total</Typography>
                  <Typography variant="body1">
                    {selectedCurrency} {total}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* ========== Advance in Paid ========== */}
            <Grid container spacing={1} sx={{ my: 1 }} justifyContent="end">
              <Grid item md={4} sm={6} xs={8}>
                <Box sx={{ bgcolor: colors.bgColor, p: 1, borderRadius: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Grand Total
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 1,
                    px: 1,
                  }}
                >
                  <Box>
                    <Typography variant="body1">Advance-in-paid</Typography>
                  </Box>
                  <Box sx={{ width: "100px", pl: 1 }}>
                    <OrderField defaultValue={0} placeholder="Amount" onChange={(e) => setAdvanceAmount(e.target.value)} />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                  <Typography variant="body1">Balance</Typography>
                  <Typography variant="body1">
                    {selectedCurrency} {(Number(total) - Number(advanceAmount)).toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              py: 1,
            }}
          >
            <Button
              onClick={() => createOrders()}
              sx={{
                minWidth: 120,
                fontSize: "14px",
                py: 1,
                textTransform: "none",
                color: colors.white,
                bgcolor: colors.primary,
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: colors.sideTab,
                  transition: "0.3s ease-in-out",
                },
              }}
            >
              Create Order
            </Button>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CreateOrder;

const Orders = {
  getOrders: "/get-order",
  getFilterOrders: "/get-filtered-orders",
  createOrder: "/create-order",
  updateOrder: "/update-order/",
  deleteOrder: "/delete-order/",
  imageOrder: "/get-image-url",
  orderDetail: "/get-orderdetail-by-id/",
  orderNo: "/get-order-no",
  orderStatus: "/update-order-status/",
};

export default Orders;

import { Dashboard, ListAlt, Person, SettingsSuggest, SettingsApplications, ExitToApp, Category, Assessment, PeopleAltTwoTone } from "@mui/icons-material";
import colors from "./style/colors";
const styles = {
  iconSize: {
    p: 0.2,
    fontSize: "22px",
  },
};

const Navigation = [
  {
    label: "Dashboard",
    icon: <Dashboard sx={styles.iconSize} />,
    path: "/",
  },
  {
    label: "Categories",
    icon: <Category sx={styles.iconSize} />,
    path: "/createcategory",
  },

  {
    label: "Clients",
    icon: <PeopleAltTwoTone sx={styles.iconSize} />,
    childPath: ["/create-client", "/clients"], // for side nave sub menu active
    children: [
      {
        label: "Create Client",
        path: "/create-client",
      },
      {
        label: "View Clients",
        path: "/clients",
      },
    ],
  },
  {
    label: "Orders",
    icon: <ListAlt sx={styles.iconSize} />,
    childPath: ["/createorder", "/orderlist"], // for side nave sub menu active
    children: [
      {
        label: "Create Order",
        path: "/createorder",
      },
      {
        label: "View Orders",
        path: "/orderlist",
      },
    ],
  },

  {
    label: "Reports",
    icon: <Assessment sx={styles.iconSize} />,
    path: "/reports",
    childPath: ["/reports", "/catg-report", "/client-report"], // for side nave sub menu active
    children: [
      {
        label: "General Report",
        path: "/reports",
      },
      {
        label: "Client Wise Report",
        path: "/client-report",
      },
      {
        label: "Category Wise Report",
        path: "/catg-report",
      },
    ],
  },

  {
    label: "Settings",
    icon: <SettingsApplications sx={styles.iconSize} />,
    path: "/setting",
  },
  // {
  //   label: "Logout",
  //   icon: <ExitToApp sx={styles.iconSize} />,
  //   path: "/login",
  // },
];

export default Navigation;

import React, { Fragment, useState } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { SecondaryButton } from "../Button/SecondaryButton";

function TableFilter({ type, categoryData, sendFilterObj, clientsName }) {
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [deliveryFrom, setDeliveryFrom] = useState("");
  const [deliveryTo, setDeliveryTo] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [clientId, setClientId] = useState("");

  const filterData = () => {
    // action(fromDate, toDate, deliveryFrom, deliveryTo, status, category);
    let filterObj = {
      order_from_date: fromDate,
      order_to_date: toDate,
      deliver_from_date: deliveryFrom,
      deliver_to_date: deliveryTo,
      status: status,
      category: category,
      client_id: clientId.id,
    };
    sendFilterObj(filterObj);
  };

  return (
    <Fragment>
      {type === "report" && (
        <Grid container spacing={1} sx={{ display: "flex", justifyContent: "space-between", px: 3, pt: 1 }}>
          <Grid item xs={6} md={2.5}>
            <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
              <FormControl fullWidth variant="outlined" sx={{ "& .MuiOutlinedInput-notchedOutline": { borderRadius: 2.5 } }}>
                <TextField size="small" label="Order From Date" variant="outlined" type="date" InputLabelProps={{ shrink: true, required: true }} onChange={(e) => setFromDate(e.target.value)} />
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} md={2.5}>
            <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
              <FormControl fullWidth variant="outlined" sx={{ "& .MuiOutlinedInput-notchedOutline": { borderRadius: 2.5 } }}>
                <TextField size="small" label="Order To Date" variant="outlined" type="date" InputLabelProps={{ shrink: true, required: true }} onChange={(e) => setToDate(e.target.value)} />
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} md={2.5}>
            <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
              <FormControl fullWidth variant="outlined" sx={{ "& .MuiOutlinedInput-notchedOutline": { borderRadius: 2.5 } }}>
                <TextField
                  size="small"
                  label="Delivery From Date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true, required: true }}
                  onChange={(e) => setDeliveryFrom(e.target.value)}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
              <FormControl fullWidth variant="outlined" sx={{ "& .MuiOutlinedInput-notchedOutline": { borderRadius: 2.5 } }}>
                <TextField size="small" label="Delivery To Date" variant="outlined" type="date" InputLabelProps={{ shrink: true, required: true }} onChange={(e) => setDeliveryTo(e.target.value)} />
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} md={2}>
            <Box sx={{ display: "flex", alignItems: "center", px: 0.8 }}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2.5,
                  },
                }}
              >
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={category}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"In Progress"}>In Progress</MenuItem>
                  <MenuItem value={"Delivered"}>Delivered</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "start", px: 0.7, pt: 1, mt: 0.5 }}>
              <SecondaryButton onClick={filterData} icon={<SearchOutlinedIcon />} title="Search" />
            </Box>
          </Grid>
        </Grid>
      )}
      {type === "category" && (
        <Grid container sx={{ display: "flex", px: 3, pt: 0.5 }} spacing={2}>
          <Grid item xs={6} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", px: 0.8 }}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2.5,
                  },
                }}
              >
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  // size="small"
                  value={category}
                  label="Category"
                  onChange={(event) => setCategory(event.target.value)}
                  sx={{
                    textAlign: "left",
                    // mb: 2,
                    borderRadius: 2,
                  }}
                >
                  {categoryData.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {/* <Grid item sm={6} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", px: 0.8 }}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2.5,
                  },
                }}
              >
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={category}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"In Progress"}>In Progress</MenuItem>
                  <MenuItem value={"Delivered"}>Delivered</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid> */}
          <Grid item xs={5} md={4}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", px: 1 }}>
              <SecondaryButton onClick={filterData} icon={<SearchOutlinedIcon />} title="Search" />
            </Box>
          </Grid>
        </Grid>
      )}
      {type === "client" && (
        <Grid container sx={{ display: "flex", px: 3, pt: 0.5 }} spacing={2}>
          <Grid item xs={6} md={4}>
            <Box>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel>Client Name</InputLabel>
                <Select
                  value={clientId.name}
                  label="Client Name"
                  sx={{
                    textAlign: "left",
                    borderRadius: 2.4,
                  }}
                >
                  {clientsName?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.name ? item.name : item}
                      onClick={() => {
                        setClientId(item);
                      }}
                    >
                      {item.name ? item.name : item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", px: 0.8 }}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 2.5,
                  },
                }}
              >
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={category}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"In Progress"}>In Progress</MenuItem>
                  <MenuItem value={"Delivered"}>Delivered</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", px: { xs: 0, md: 1 } }}>
              <SecondaryButton onClick={filterData} icon={<SearchOutlinedIcon />} title="Search" />
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default TableFilter;

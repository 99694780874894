import React, { Fragment } from "react";
import Layout from "../../layout/Layout";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import colors from "../../style/colors";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { PeopleAltTwoTone, Delete, AddCircle, EditTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { SecondaryButton } from "../../components/Button/SecondaryButton";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import ClientsServices from "../../apis/Clients/ClientsServices";
import { useEffect } from "react";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/Toaster";
import { Loading } from "../../components/UI/Loader";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 700,
    border: 0,
    whiteSpace: "nowrap",
    backgroundColor: colors.bgColor,
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
  },
}));

//* Table Header Array
const tableHeader = ["Client Id", "Name", "Email", "Phone No", "State", "Status", "Action"];

const ClientsList = () => {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loader, setLoader] = useState(true);

  // *For Pagination
  const [totalCount, setTotalCount] = useState();
  // const [pageLimit, setPageLimit] = useState(10);
  // const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // *For GET CLIENTS API
  const [clientsData, setClientsData] = useState([]);
  const [clientId, setClientId] = useState("");

  //* API FOR GET ALL CLIENTS
  const getClients = async () => {
    setLoader(true);
    try {
      const { data } = await ClientsServices.getClients();
      if (data === null) return;
      setClientsData(data);
      setTotalCount(data.length);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //* API FOR DELETE CATEGORIES
  const deleteClient = async () => {
    try {
      const { data } = await ClientsServices.deleteClient(clientId);
      if (data === null) return;
      SuccessToaster("client Deleted");
      getClients();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* For Users Status
  const updateUserStatus = async (id, active) => {
    try {
      let obj = {
        is_active: active === "1" ? 0 : 1,
      };
      const { status, responseCode, message } = await ClientsServices.clientStatus(id, obj);
      if (status === true && responseCode === 200) {
        SuccessToaster(message, 1500);
        getClients();
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);
  return (
    <Fragment>
      <ConfirmationDialog open={confirmDialog} onClose={() => setConfirmDialog(false)} action={deleteClient} title="Delete this client?" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          borderRadius: "16px",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PeopleAltTwoTone sx={{ mr: 1 }} />
          <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            Client List
          </Typography>
        </Box>

        <BreadCrumbs currentPage={["Clients"]} />
      </Box>
      {loader ? (
        <Box sx={{ display: "flex", justifyContent: "center", pl: 18, py: 4 }}>
          <Loading />
        </Box>
      ) : (
        <Fragment>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "end", pt: 1 }}>
              <SecondaryButton onClick={() => navigate("/create-client")} icon={<AddCircle />} title="New Client" />
            </Grid>
          </Grid>
          {/* ========== Table ========== */}
          <TableContainer
            component={Paper}
            sx={{
              mt: 2,
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.08)",
              borderRadius: 2,
              maxHeight: { xs: "auto", sm: "auto", md: "calc(100vh - 300px)" },
              maxWidth: { xs: "auto", sm: "auto" },
            }}
          >
            <Table stickyHeader sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  {tableHeader.map((item, index) => (
                    <Cell key={index}>{item}</Cell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {clientsData?.length > 0 ? (
                  <Fragment>
                    {clientsData?.map((item, index) => (
                      <Row key={index} sx={{ bgcolor: index % 2 !== 0 && colors.hexGray }}>
                        <Cell>
                          <Typography variant="body1">{item.id}</Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{item.name}</Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{item.email}</Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{item.phone}</Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{item.state}</Typography>
                        </Cell>
                        <Cell>
                          <Switch size="small" checked={item.is_active === "1"} onClick={() => updateUserStatus(item.id, item.is_active)} />
                        </Cell>
                        <Cell>
                          <Box display="flex">
                            <Tooltip title="Edit">
                              <IconButton onClick={() => navigate(`/update-client/${item.id}`, { state: { data: item } })}>
                                <EditTwoTone sx={{ color: colors.primary }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setConfirmDialog(!confirmDialog);
                                  setClientId(item.id);
                                }}
                              >
                                <Delete sx={{ color: colors.opal }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Cell>
                      </Row>
                    ))}
                  </Fragment>
                ) : (
                  <Row>
                    <Cell colSpan={tableHeader.length} align="center" sx={{ fontWeight: 600 }}>
                      No Data Found
                    </Cell>
                  </Row>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ========== Pagination ========== */}
          <Pagination
            currentPage={currentPage}
            pageSize={10}
            // onPageSizeChange={changePageSize}
            tableCount={clientsData?.length}
            totalCount={totalCount}
            // onPageChange={(page) => changePage(page)}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ClientsList;

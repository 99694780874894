import React, { useState } from "react";

const useProvideAuth = () => {
  const [user, setUser] = useState(localStorage.getItem("token"));
  const [userName, setUserName] = useState(localStorage.getItem("name"));
  const [image, setImage] = useState(localStorage.getItem("image"));

  // *User
  const userLogin = (data) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("name", data.userName);
    localStorage.setItem("email", data.userEmail);
    localStorage.setItem("image", data.userImage);
    setUser(data.token);
    setUserName(data.userName);
    setImage(data.userImage);
  };

  // *Logout
  const userLogout = async () => {
    setUser(null);
    localStorage.clear();
  };

  // *Verify Token
  const verifyToken = () => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("name");
    setUser(token);
    setUserName(name);
  };

  const updateUserImage = (image) => {
    setImage(image);
    localStorage.setItem("image", image);
  };

  return {
    userName,
    user,
    image,
    updateUserImage,
    userLogin,
    verifyToken,
    userLogout,
  };
};

export default useProvideAuth;

import React, { Fragment } from "react";
import { SideNav } from "./SideNav";
import { Box, Toolbar } from "@mui/material";
import colors from "../style/colors";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  const drawerWidth = 240;
  return (
    <Fragment>
      <SideNav />
      <Box sx={{ bgcolor: colors.hexGray }}>
        <Box
          sx={{
            pt: 1,
            display: "flex",
            justifyContent: { xs: "start", sm: "start", md: "end" },
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              p: 3,
              width: {
                xs: "100%",
                sm: `100%`,
                md: `calc(100% - ${drawerWidth}px)`,
              },
            }}
          >
            <Toolbar />
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

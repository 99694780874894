export const userColumns = [
  {
    field: "user",
    headerName: "User",
    width: 210,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            style={{
              borderRadius: "50%",
              objectFit: "cover",
              width: 32,
              height: 32,
              marginRight: "16px",
            }}
            src={params.row.img}
            alt="avatar"
          />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 190,
  },

  {
    field: "age",
    headerName: "Age",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => {
      return <div>{params.row.status}</div>;
    },
  },
];

//*temporary Rows data
export const userRows = [
  {
    id: 1,
    ordernum: "10001",
    orderdate: "02/05/2023",
    deliverydate: "02/06/2023",
    total: "8000",
    paid: "5000",
    balance: "3000",
    username: "Arnold Rodrigo",
    phone: "+971 55 816 2583",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "Delivered",
    email: "1snow@gmail.com",
    rating: 5,
  },
  {
    id: 2,
    ordernum: "10002",
    orderdate: "10/05/2023",
    deliverydate: "05/06/2023",
    total: "5000",
    paid: "2000",
    balance: "3000",
    username: "Jamie Lannister",
    phone: "+971 55 816 2583",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "In Progress",
    rating: 4,
  },
  {
    id: 3,
    ordernum: "10003",
    orderdate: "26/04/2023",
    deliverydate: "22/05/2023",
    total: "19000",
    paid: "10000",
    balance: "9000",
    username: "Lannister",
    phone: "+971 55 816 2583",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "Pending",
    rating: 5,
  },
  {
    id: 4,
    ordernum: "10004",
    orderdate: "01/04/2023",
    deliverydate: "03/05/2023",
    total: "3000",
    paid: "2000",
    balance: "1000",
    username: "Stark",
    phone: "+971 55 816 2583",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "Pending",
    rating: 5,
  },
];

//*temporary Rows data
export const clientRows = [
  {
    id: 1,
    username: "Snow",
    order: "4",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "Active",
    email: "1snow@gmail.com",
    rating: "+971 55 400 5736",
  },
  {
    id: 2,
    username: "Jamie Lannister",
    order: "6",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "In Active",
    rating: "+971 55 254 4169",
  },
  {
    id: 3,
    username: "Lannister",
    order: "2",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "Active",
    rating: "+971 55 460 4901",
  },
];

// table category

export const furnitureRows = [
  {
    id: 1,
    category: "Table",
    status: "Active",
    createdAt: "02/05/2023",
  },
  {
    id: 2,
    category: "Chair",
    status: "InActive",
    createdAt: "02/05/2023",
  },
  {
    id: 3,
    category: "Bed",
    status: "Active",
    createdAt: "02/05/2023",
  },
  {
    id: 3,
    category: "Dresser",
    status: "Active",
    createdAt: "02/05/2023",
  },
];
//*temporary payment data
export const paymentRows = [
  {
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: true,
    amount: 14.81,
    updatedAt: "2022-12-16T07:10:17.000Z",
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: true,
    amount: 21.54,
    updatedAt: "2022-12-16T07:10:17.000Z",
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: false,
    amount: 81.33,
    updatedAt: "2022-12-16T07:10:17.000Z",
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: true,
    amount: 38.6,
    updatedAt: "2022-12-16T07:10:17.000Z",
  },
];

//*temporary Integration data
export const integration = [
  {
    title: "Stark",
    desc: "Lorem Ipsum is simply dummy text of the  industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    date: "2003",
    time: "2003",
  },
  {
    title: "Lannister",
    desc: "Lorem Ipsum is simply dummy text of the printing . Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    date: "2004",
    time: "2003",
  },
  {
    title: "John",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    date: "2007",
    time: "2003",
  },
  {
    title: "Arnold",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    date: "2008",
    time: "2003",
  },
  {
    title: "Mike",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's  text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    date: "2009",
    time: "2009",
  },
];

//* simple Card data
export const simpleCardData = {
  totalRides: 1200,
  totalIncome: 9700,
  totalDrivers: 700,
  title: {
    rides: "Total Rides",
    income: "Total Income",
    driver: "Total Driver",
  },
};

export const sosTripData = {
  tripId: 1200,
  time: 9700,
  carName: "Civic2020",
  carNo: "AFD-122",
  title: {
    id: "Trip Id",
    time: "Time",
    name: "Amount",
    number: "car Number",
  },
};

const colors = {
  primary: "rgb(44, 165, 141)",
  secondary: "rgb(17, 25, 39)",
  bgColor: "#f2f5f9",
  sideTab: "rgba(44, 165, 141,0.7)",
  tabBg: `rgba(255, 255, 255,0.4)`,
  // sideNav: `linear-gradient(to left top, #246244, #30694d, #3c7056, #487760, #537e69)`,
  sideNav: ` linear-gradient(to top, #c4f8df, #c9fada, #d0fcd5, #d8fecf, #e1ffc9);`,
  top: ` linear-gradient(to right, #c4f8df, #c9fada, #d0fcd5, #d8fecf, #e1ffc9)`,
  primeGradient: ` linear-gradient(to right, #1e7866, #218370, #258e79, #289a83, #2ca58d)`,
  cardShadow: `rgba(145, 158, 171, 0.2) 
    0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px`,
  shadow: "##8D8D8D29",
  white: "#ffff",
  whiteText: "#fdfdfd",
  lightFont: "rgb(105, 117, 134)",
  hexGray: "#FAFAFA",
  Onahau: "#c3f1f7",
  lowPurple: "#c9d5f5",
  redicalRed: "#f5365c",
  bgHeart: "#f2c7d1",
  silver: "#C5C5C5",
  ufoGreen: "#28C66F",
  opal: "#ed706f",
  opalBg: "#fff1f0",
  completeBg: "rgba(87, 202, 34, 0.2)",
  active: "#B8eecf",
  iconBg: "#ebf8ee",
  offline: "#f7cdcb",
  skyBlue: "#50ecfa",
  skyBlueBg: "#d2f9fc",
  orangeBg: "rgba(255, 162, 24, 0.1)",
  pendingBg: "rgba(255, 163, 25, 0.2)",
  pending: "rgb(255, 170, 29)",
  yellow: "#f5be19",
  yellowG: "#ffd600",
  yellowBg: "#fae5bb",
  primBlue: "#2196f3",
};

export default colors;

import { Login } from "../views/Auth/Login";
import CategoryCreate from "../views/Category/CategoryCreate";
import ClientsList from "../views/Clients/ClientsList";
import CreateClient from "../views/Clients/CreateClient";
import UpdateClient from "../views/Clients/UpdateClient";
import { Dashboard } from "../views/Dashboard/Dashboard";
import CreateOrder from "../views/Orders/CreateOrder";
import OrderDetail from "../views/Orders/OrderDetail";
import OrdersList from "../views/Orders/OrdersList";
import UpdateOrder from "../views/Orders/UpdateOrder";
import UserProfile from "../views/Profile/UserProfile";
import CategoryReport from "../views/Reports/CategoryReport";
import ClientReport from "../views/Reports/ClientReport";
import Report from "../views/Reports/Report";

const WebRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/setting",
    component: <UserProfile />,
  },
  {
    path: "/orderlist",
    component: <OrdersList />,
  },
  {
    path: "/createorder",
    component: <CreateOrder />,
  },
  {
    path: "/updateorder/:id",
    component: <UpdateOrder />,
  },
  {
    path: "/orderdetail/:id",
    component: <OrderDetail />,
  },
  {
    path: "/createcategory",
    component: <CategoryCreate />,
  },
  {
    path: "/clients",
    component: <ClientsList />,
  },
  {
    path: "/create-client",
    component: <CreateClient />,
  },
  {
    path: "/update-client/:id",
    component: <UpdateClient />,
  },
  {
    path: "/reports",
    component: <Report />,
  },
  {
    path: "/catg-report",
    component: <CategoryReport />,
  },
  {
    path: "/client-report",
    component: <ClientReport />,
  },
];

export default WebRoutes;

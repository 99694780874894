import React from "react";
import colors from "../../style/colors";
//* MUI Imports
import { Box, Card, CardContent, Typography } from "@mui/material";

function SimpleCard(props) {
  return (
    <Card
      display="flex"
      sx={{
        minWidth: "210px",
        minHeight: "150px",
        borderRadius: "16px",
        boxShadow: colors.cardShadow,
      }}
    >
      <CardContent sx={{ p: 2, "&:last-child": { pb: 1 } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column", pt: 2.5, px: 1.5 }}>
            <Typography fontFamily="Public Sans, sans-serif" fontSize="0.74rem" fontWeight="550" color="rgb(99, 115, 129)">
              {props.title}
            </Typography>
            <Typography fontFamily="Public Sans, sans-serif" fontSize="2rem" fontWeight="700">
              {props.total ?? 0}
              <span style={{ fontSize: "13px" }}>AED</span>
            </Typography>
            <Typography fontFamily="Public Sans, sans-serif" fontSize="0.74rem" fontWeight="500" color={props?.color}>
              {props.sub}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              bgcolor: "rgb(244, 246, 248)",
            }}
          >
            <Box
              sx={{
                width: "120px",
                height: "120px",
              }}
            >
              {props.icon}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SimpleCard;

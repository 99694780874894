import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogTitle, IconButton, DialogContent, Typography, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../style/colors";

const OrderStatusDialog = ({ open, onClose, status, action, paid }) => {
  const [orderStatus, setOrderStatus] = useState();
  const [checked, setChecked] = useState();

  useEffect(() => {
    setOrderStatus(status);
  }, [status]);

  useEffect(() => {
    setChecked(paid === 1 ? true : false);
  }, [paid]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "400px",
          maxWidth: "400px",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" sx={{ backgroundImage: colors.top }}>
        <Box py={2} px={4}>
          <Typography variant="h6" fontWeight="bold">
            Update Order Status
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ "&:hover": { backgroundColor: colors.top } }}>
          <CancelIcon
            sx={{
              fontSize: "26px",
              color: colors.secondary,
              mr: 1,
              "&:hover": { color: colors.lightFont },
            }}
          />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 4, px: 5 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2.5,
              },
            }}
          >
            <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={status}
              label="Order Status"
              onChange={(event) => {
                setOrderStatus(event.target.value);
                setChecked(false);
              }}
            >
              <MenuItem value={"Delivered"}>Delivered</MenuItem>
              <MenuItem value={"In Progress"}>In Progress</MenuItem>
              <MenuItem value={"Pending"}>Pending</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {orderStatus !== "Pending" && (
          <Box pt={1}>
            <FormGroup>
              <FormControlLabel label="Mark as Paid" control={<Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />} />
            </FormGroup>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
          <Button
            onClick={() => action(orderStatus ? orderStatus : status, checked)}
            sx={{
              minWidth: 120,
              fontSize: "14px",
              textTransform: "none",
              color: colors.white,
              bgcolor: colors.primary,
              borderRadius: "8px",
              "&:hover": {
                bgcolor: colors.lightFont,
                transition: "0.3s ease-in-out",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderStatusDialog;

import React, { useState } from "react";
import { Box, Dialog, IconButton, DialogContent, Typography, Button, Grid, CircularProgress } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../../style/colors";
import InputField from "../../TextField/InputField";
import { useForm } from "react-hook-form";
import ClientsServices from "../../../apis/Clients/ClientsServices";
import { ErrorToaster, SuccessToaster } from "../../Toaster/Toaster";
import SelectField from "../../SelectField/SelectField";

const statesName = ["Abu Dhabi", "Dubai", "Sharjah", "Umm Al Qaiwain", "Fujairah", "Ajman", "Ras Al Khaimah"];

const AddClientDialog = ({ open, onClose, action }) => {
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //* API FOR CREATE CLIENTS
  const createClient = async (formData) => {
    setLoading(true);
    try {
      const obj = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        state: formData.state,
      };
      const { data } = await ClientsServices.createClients(obj);
      if (data === null) return;
      reset();
      setLoading(false);
      action();
      SuccessToaster("Client Created Successfully");
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: "20px", minWidth: "500px", maxWidth: "500px" },
      }}
    >
      <Box display="flex" justifyContent="space-between" sx={{ backgroundImage: colors.top }}>
        <Box py={2} px={4}>
          <Typography variant="h6" fontWeight="bold">
            Add Client
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ "&:hover": { backgroundImage: colors.top } }}>
          <CancelIcon
            sx={{
              mr: 1,
              fontSize: "26px",
              color: colors.secondary,
              "&:hover": { color: colors.lightFont },
            }}
          />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 2 }}>
        <Typography component="form" onSubmit={handleSubmit(createClient)}>
          <Grid container spacing={1} p={2}>
            <Grid item xs={6}>
              <InputField
                label="Name"
                error={errors?.name?.message}
                register={register("name", {
                  required: "Enter Client's name",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Email"
                error={errors?.email?.message}
                register={register("email", {
                  required: "Enter Client's email",
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Address"
                error={errors?.address?.message}
                register={register("address", {
                  required: "Enter Client's address",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Phone Number"
                error={errors?.phone?.message}
                register={register("phone", {
                  required: "Enter Client's phone number",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SelectField
                  label="State"
                  setState={(item) => {
                    setSelectedState(item);
                  }}
                  stateValue={selectedState}
                  data={statesName}
                  error={errors?.state?.message}
                  register={register("state", {
                    required: "Select Client's state",
                  })}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
            <Button
              type="submit"
              onClick={onClose}
              sx={{
                minWidth: 120,
                fontSize: "14px",
                textTransform: "none",
                color: colors.white,
                bgcolor: colors.primary,
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: colors.lightFont,
                  transition: "0.3s ease-in-out",
                },
              }}
            >
              {loading ? <CircularProgress color="inherit" size={24} /> : "Submit"}
            </Button>
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AddClientDialog;

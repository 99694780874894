const Auth = {
  login: "/login",
  logout: "/logout",
  userInfo: "/get-user-info",
  updateInfo: "/update-user",
  updateProfileImage: "/update-user-image",
  changePassword: "/change-password",
};

export default Auth;

import React from "react";
import { Box, Dialog, DialogTitle, IconButton, DialogContent, Typography, DialogActions, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../style/colors";

function ConfirmationDialog({ open, onClose, action, title }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "400px",
          maxWidth: "400px",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box></Box>
        {/* <DialogTitle variant='h5' fontWeight="bold">Delete This User?</DialogTitle> */}
        <IconButton onClick={onClose} sx={{ "&:hover": { backgroundColor: colors.white } }}>
          <CancelIcon sx={{ fontSize: "26px", color: colors.opal, mr: 1, "&:hover": { color: "red" } }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 1 }}>
        <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="h5" fontWeight="bold">
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          You won't be able to revert this!
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <Button
          onClick={() => {
            action();
            onClose();
          }}
          variant="contained"
          sx={{
            mx: 1,
            width: "110px",
            height: "28px",
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: "10px",
            textTransform: "capitalize",
            backgroundColor: colors.redicalRed,
            color: colors.opalBg,
            "&:hover": {
              backgroundColor: colors.opal,
            },
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;

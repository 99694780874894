import { useEffect, useState } from "react";
import { ArrowRightAlt } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import colors from "../../style/colors";
import { PaginationButton } from "../Button/SecondaryButton";
import { DOTS, usePagination } from "../../hooks/usePagination";

const useStyles = makeStyles({
  pageBtn: {
    display: "block !important",
    minWidth: "32px !important",
    "&:hover": {
      backgroundColor: `${colors.secondary} !important`,
      color: `${colors.white} !important`,
    },
  },
  pageBtnActive: {
    backgroundColor: `${colors.secondary} !important`,
    color: `${colors.white} !important`,
    "&:hover": {
      backgroundColor: `${colors.secondary} !important`,
      color: `${colors.white} !important`,
    },
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.black,
  },
});

function Pagination({ tableCount, totalCount, currentPage, siblingCount = 1, onPageChange, pageSize, onPageSizeChange, filterObj }) {
  const classes = useStyles();

  const [initialCount, setInitialCount] = useState(0);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  let lastPage = paginationRange[paginationRange?.length - 1];

  // const initialCount = (currentPage - 1) * pageSize
  useEffect(() => {
    setInitialCount((currentPage - 1) * pageSize);
  }, [currentPage, pageSize]);

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ mt: 0 }}>
      <Grid item xl={6} md={6} xs={12} sx={{ display: "flex" }}>
        <Typography variant="body2" sx={{ color: colors.smokeyGrey }}>
          show
        </Typography>
        <Typography
          component="select"
          name="countPerPage"
          sx={{
            mx: 1,
            px: 2,
            width: "auto",
            border: "none",
            boxShadow: "0px 4px 4px 0px #D7DCE8",
          }}
          onChange={onPageSizeChange}
        >
          <Typography component="option" value="15" sx={{ color: colors.smokeyGrey }}>
            10
          </Typography>
          {/* <Typography component="option" value="30" sx={{ color: colors.smokeyGrey }}>30</Typography>
          <Typography component="option" value="50" sx={{ color: colors.smokeyGrey }}>50</Typography> */}
        </Typography>
        <Typography variant="body2" sx={{ color: colors.smokeyGrey }}>
          showing &nbsp;
          <Typography component="span" className={classes.text}>
            {totalCount === 0 ? 0 : initialCount + 1}
          </Typography>
          &nbsp; to &nbsp;
          <Typography component="span" className={classes.text}>
            {initialCount + tableCount}
          </Typography>
          &nbsp; of &nbsp;
          <Typography component="span" className={classes.text}>
            {totalCount}
          </Typography>
          &nbsp; entries
        </Typography>
      </Grid>
      <Grid item xl={6} md={6} xs={12} sx={{ display: "flex", justifyContent: "right" }}>
        <PaginationButton
          disabled={currentPage === 1 ? true : false}
          onClick={(filterObj) => onPageChange(currentPage - 1, filterObj)}
          startIcon={<ArrowRightAlt sx={{ fontSize: "22px", transform: "rotate(180deg)" }} />}
        >
          {"prev"}
        </PaginationButton>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <Typography
                sx={{
                  width: 40,
                  fontSize: 20,
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                ...
              </Typography>
            );
          }
          return (
            <PaginationButton
              key={index}
              onClick={(filterObj) => onPageChange(pageNumber, filterObj)}
              className={clsx(classes.pageBtn, {
                [classes.pageBtnActive]: pageNumber === currentPage,
              })}
            >
              {pageNumber}
            </PaginationButton>
          );
        })}
        <PaginationButton disabled={currentPage === lastPage || totalCount === 0 ? true : false} onClick={() => onPageChange(currentPage + 1)} endIcon={<ArrowRightAlt sx={{ fontSize: "22px" }} />}>
          {"next"}
        </PaginationButton>
      </Grid>
    </Grid>
  );
}

export default Pagination;

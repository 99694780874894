import { Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import colors from "../../style/colors";
import PaymentReportsCard from "../../components/Cards/PaymentReportsCard";
import SimpleCard from "../../components/Cards/SimpleCard";
import SalesReportCard from "../../components/Cards/SalesReportCard";
import { CustomerIcon, PersonIcon, SvgIcon } from "../../assets/Images";
import LineChart from "../../components/Chart/LineChart";
import { ErrorToaster } from "../../components/Toaster/Toaster";
import { useEffect } from "react";
import StatsServices from "../../apis/Stats/StatsServices";

export const Dashboard = () => {
  const delivered = "Total Amount";
  const inProgress = "Total Amount";
  const pending = "Total Amount";
  const pending_sub = "In Pending Orders";
  const inProgress_sub = "In-Progress Orders";
  const delivered_sub = "In Delivered Orders";

  const [statsData, setStatsData] = useState([]);
  const [chart, setChart] = useState(null);

  // const [loading, setLoading] = useState(true);
  //* API FOR GET CHART DATA
  const chartData = async () => {
    try {
      const { data } = await StatsServices.chartData();
      if (data === null) return;
      setChart(data[0]);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* API FOR GET STATS
  const getStats = async () => {
    try {
      const { data } = await StatsServices.cardStats();
      if (data === null) return;
      setStatsData(data[0]);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getStats();
    chartData();
  }, []);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} mb={1}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <SimpleCard title={pending} sub={pending_sub} total={statsData.pending} icon={<SvgIcon />} color={colors.pending} />
            </Grid>
            <Grid item xs={12} md={4}>
              <SimpleCard title={inProgress} sub={inProgress_sub} total={statsData.inprogress} icon={<PersonIcon />} color={colors.redicalRed} />
            </Grid>
            <Grid item xs={12} md={4}>
              <SimpleCard title={delivered} sub={delivered_sub} total={statsData.delivered} icon={<CustomerIcon />} color={colors.ufoGreen} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mb={1}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4} mb={1}>
              <SalesReportCard />
            </Grid>
            <Grid item xs={12} md={8} mb={1}>
              <LineChart chartData={chart} />
            </Grid>
            <Grid item xs={12} md={12} mb={1}>
              <PaymentReportsCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

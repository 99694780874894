import React, { Fragment, useState } from "react";
import { Menu, ExpandLess, ExpandMore, NotificationsActiveTwoTone, Logout, LockOutlined, ArrowRightAlt, ArrowForward, LogoutOutlined, AccountCircle } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import colors from "../style/colors";
import Image from "../assets/Images";
import Navigation from "../Navigation";
import { useLocation, useNavigate, Link } from "react-router-dom";
import AuthServices from "../apis/Auth/AuthServices";
import useAuth from "../hooks/useAuth";
import { imageBaseUrl } from "../utils";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 0, 2, 1),
  margin: "8px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const SideNav = (props) => {
  const { userLogout, image } = useAuth();
  const { window } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //* For List Open and Expand
  // const [openList, setOpenList] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];
    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }
    setExpand(newExpand);
  };
  //* For Collapse List Color
  const handleListItemColor = (index) => {
    const arrIndex = selectedIndex.indexOf(index);
    const newSelected = [...selectedIndex];
    if (arrIndex === -1) {
      newSelected.push(index);
    } else {
      newSelected.splice(arrIndex, 1);
    }
    setSelectedIndex(newSelected);
  };

  //* FOR LOGOUT FUNCTIONALITY
  // const logout = async () => {
  //   try {
  //     const { data } = await AuthServices.logout();
  //     if (data === null) return;
  //     userLogout();
  //     navigate("/login");
  //   } catch (error) {}
  // };

  const drawer = (
    <Fragment>
      <DrawerHeader>
        <img src={Image.logo} alt="Scriptio" style={{ width: "180px", marginRight: "10px", zIndex: 1 }} />
      </DrawerHeader>
      <List sx={{ m: 0.6, p: 0.6, height: "calc(100vh - 190px)", overflow: "auto" }}>
        {Navigation.map((item, index) => (
          //*Collapse List Items
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            {item.children ? (
              <Fragment>
                <ListItemButton
                  onClick={() => {
                    item.childPath && handleCollapse(item.label);
                    setOpen(true);
                    handleListItemColor(index);
                  }}
                  selected={selectedIndex[0] === index || selectedIndex[1] === index || item.childPath.includes(pathname)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    m: 1,
                    borderRadius: "8px",
                    transition: "450ms ease",
                    "&.Mui-selected": {
                      bgcolor: colors.sideTab,
                      color: colors.whiteText,
                      ":hover": {
                        bgcolor: colors.tabBg,
                        color: colors.primary,
                      },
                    },
                    ":hover": {
                      ".MuiListItemIcon-root": {
                        color: colors.primary,
                      },
                      backgroundColor: colors.iconBg,
                      color: colors.primary,
                      ml: "12.5px",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                      // bgcolor: colors.white,
                      borderRadius: "6px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                  {expand.indexOf(item.label) !== -1 ? (
                    <ExpandLess
                      sx={{
                        opacity: open ? 1 : 0,
                        ...(!open && { display: "none" }),
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        opacity: open ? 1 : 0,
                        ...(!open && { display: "none" }),
                      }}
                    />
                  )}
                </ListItemButton>

                <Collapse in={expand.indexOf(item.label) !== -1 || item.childPath?.indexOf(pathname) !== -1 ? true : false} timeout="auto" unmountOnExit sx={{ ml: 1.8 }}>
                  <List component="div" disablePadding>
                    {item.children.map((child, i) => (
                      <ListItemButton
                        key={i}
                        selected={pathname === child.path ? true : false}
                        component={Link}
                        to={child.path}
                        sx={{
                          borderRadius: "8px",
                          pl: 1,
                          // listStyleType: "disc",
                          "&.Mui-selected": {
                            backgroundColor: "transparent",
                            color: colors.primary,
                            ":hover": {
                              backgroundColor: "transparent",
                              color: colors.primary,
                            },
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: open ? 1 : "auto",
                            justifyContent: "start",
                            borderRadius: "6px",
                            color: pathname === child.path ? colors.primary : "",
                          }}
                        >
                          <ArrowForward sx={{ fontSize: "16px", mr: 1 }} />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: "15px" }}>{child.label}</Typography>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <Fragment>
                {/* {role === "manager" && index === 8 ? (<Divider sx={{ my: 2 }} />) : (index === 7 && (<Divider sx={{ my: 2 }} />))} */}
                <ListItemButton
                  selected={pathname === item.path ? true : false}
                  onClick={() => {
                    navigate(item.path);
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    m: 1,
                    transition: "450ms ease",
                    borderRadius: "8px",
                    "&.Mui-selected": {
                      bgcolor: colors.sideTab,
                      color: colors.whiteText,
                      ":hover": {
                        bgcolor: colors.tabBg,
                        color: colors.primary,
                      },
                    },

                    ":hover": {
                      ".MuiListItemIcon-root": {
                        color: colors.primary,
                      },
                      backgroundColor: colors.iconBg,
                      color: colors.primary,
                      ml: "12.5px",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                      color: pathname === item.path ? colors.whiteText : "",
                      // bgcolor: colors.white,
                      borderRadius: "6px",
                      // "&:hover": {
                      //   color: colors.primary,
                      // },
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </Fragment>
            )}
          </ListItem>
        ))}
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => {
              userLogout();
            }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              m: 1,
              transition: "450ms ease",
              borderRadius: "8px",
              "&.Mui-selected": {
                bgcolor: colors.sideTab,
                color: colors.whiteText,
                ":hover": {
                  bgcolor: colors.tabBg,
                  color: colors.primary,
                },
              },

              ":hover": {
                ".MuiListItemIcon-root": {
                  color: colors.primary,
                },
                backgroundColor: colors.iconBg,
                color: colors.primary,
                ml: "12.5px",
              },
            }}
          >
            <ListItemIcon
              sx={{
                ml: 0.2,
                minWidth: 0,
                mr: open ? 1 : "auto",
                justifyContent: "center",
                // bgcolor: colors.white,
                borderRadius: "6px",
                // "&:hover": {
                //   color: colors.primary,
                // },
              }}
            >
              <Logout sx={{ p: 0.2, fontSize: "22px" }} />
            </ListItemIcon>
            <ListItemText
              primary={"Logout"}
              sx={{
                opacity: open ? 1 : 0,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Fragment>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          py: 0.2,
          px: 1.5,
          bgcolor: `rgb(255 255 255 / 0.6)`,
          backdropFilter: "blur(10px)",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{}}>
          <IconButton color={colors.secondary} aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { md: "none" } }}>
            <Menu />
          </IconButton>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
            <Box></Box>
            <Box display="flex">
              {/* Notification Bell */}
              <Tooltip title="Open Notification">
                <IconButton
                  // onClick={(e) => { setAnchorElNotify(e.currentTarget); }}
                  sx={{ px: 1.5 }}
                >
                  <Badge color="error">
                    <NotificationsActiveTwoTone sx={{ color: colors.lightFont }} />
                  </Badge>
                </IconButton>
              </Tooltip>
              {/* User Avatar */}
              <Fragment>
                <Tooltip title="Admin">
                  <IconButton
                    onClick={(e) => {
                      setAnchorElUser(e.currentTarget);
                    }}
                    sx={{ px: 0.5, m: 0.5 }}
                  >
                    <Badge badgeContent={""}>
                      <Avatar src={imageBaseUrl + image} alt="AdminProfile"></Avatar>
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorElUser}
                  keepMounted
                  open={Boolean(anchorElUser)}
                  onClose={() => setAnchorElUser(null)}
                  // sx={{ mt: "px" }}
                  // id="menu-appBar"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <LogoutOutlined />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Fragment>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              borderRight: colors.white,
              width: drawerWidth,
              backgroundImage: `url(${Image.sideNav})`,
              position: "absolute",
              backgroundSize: "100% 100%",
              backgroundPosition: "center center",
              "&:before": {
                position: "absolute",
                width: "100%",
                height: "100%",
                content: '""',
                display: "block",
                backgroundImage: colors.sideNav,
                opacity: "0.9",
              },
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              // bgcolor: colors.sideNav,
              boxSizing: "border-box",
              borderRight: colors.white,
              width: drawerWidth,
              backgroundImage: `url(${Image.sideNav})`,
              // position: "absolute",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              "&:before": {
                position: "absolute",
                width: "100%",
                height: "100vh",
                content: '""',
                display: "block",
                backgroundImage: colors.sideNav,
                opacity: "0.8",
              },
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

import React, { Fragment, useRef } from "react";
import Layout from "../../layout/Layout";
import { Avatar, Box, Button, Card, CardContent, CardMedia, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Image from "../../assets/Images";
import colors from "../../style/colors";
import InputField from "../../components/TextField/InputField";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { PrimButton } from "../../components/Button/SecondaryButton";
import AuthServices from "../../apis/Auth/AuthServices";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/Toaster";
import { useEffect } from "react";
import { Loading } from "../../components/UI/Loader";
import { useForm } from "react-hook-form";
import SelectField from "../../components/SelectField/SelectField";
import { imageBaseUrl } from "../../utils";
import useAuth from "../../hooks/useAuth";

const UserProfile = () => {
  const { updateUserImage } = useAuth();
  const { register, handleSubmit } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    watch,
    reset,
  } = useForm();
  const password = useRef({});
  password.current = watch("newPassword", "");

  const statesName = ["Abu Dhabi", "Dubai", "Sharjah", "Umm Al Qaiwain", "Fujairah", "Ajman", "Ras Al Khaimah"];
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedState, setSelectedState] = useState("");

  // *For Show Password Toggle
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // *For Image
  const [image, setImage] = useState();

  const handleImageChange = async (event) => {
    // const data = URL.createObjectURL(event.target.files[0]);
    // setImage(data);
    try {
      const formData = new FormData();
      formData.append("image", event.target.files[0]);
      const { data, message } = await AuthServices.updateProfileImage(formData);
      if (data === null) return;
      SuccessToaster(message);
      setImage(data);
      updateUserImage(data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* API FOR GET USER INFO
  const getInfo = async () => {
    setLoading(true);
    try {
      const { data } = await AuthServices.userInfo();
      if (data === null) return;
      setUserData(data[0]);
      setImage(data[0].image);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //* API FOR UPDATE USER INFO
  const updateInfo = async (form) => {
    try {
      const formData = new FormData();
      formData.append("name", form.name);
      formData.append("email", form.email);
      formData.append("phone", form.phone);
      formData.append("address", form.address);
      formData.append("state", form.state);
      formData.append("website", form.website);
      formData.append("about", form.about ? form.about : null);
      const { data, message } = await AuthServices.updateInfo(formData);
      if (data === null) return;
      setUserData({
        name: form.name,
        email: form.email,
        phone: form.phone,
        address: form.address,
        state: form.state,
        website: form.website,
        about: form.about ? form.about : null,
      });
      SuccessToaster(message);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Change Password
  const changePassword = async (formData) => {
    try {
      let obj = {
        old_password: formData.oldPassword,
        new_password: formData.newPassword,
      };
      const { responseCode, message } = await AuthServices.changePassword(obj);
      if (responseCode === 200) {
        reset();
        SuccessToaster(message);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          borderRadius: "16px",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Person sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            My Profile
          </Typography>
        </Box>
        <BreadCrumbs currentPage={["My Profile"]} />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" pl={16} py={4}>
          <Loading />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Fragment>
            <Grid item xs={12} sm={5} md={4}>
              <Card
                sx={{
                  py: 3,
                  borderRadius: "16px",
                  boxShadow: colors.cardShadow,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      pb: 0.5,
                    }}
                  >
                    <CardMedia component={"img"} image={imageBaseUrl + image} sx={{ borderRadius: "80px", height: "150px", width: "150px", objectFit: "contain" }} />
                    {/* <Avatar alt="Remy Sharp" src={image} sx={{ width: 150, height: 150 }} /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "12px",
                      bgcolor: colors.hexGray,
                      my: 1,
                      p: 2,
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {userData.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "12px",
                      bgcolor: colors.hexGray,
                      my: 2,
                      p: 2,
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                      {userData.address}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      py: 1,
                    }}
                  >
                    <span
                      className="btn btn-primary btn-file"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        color: "white",
                        height: "50px",
                        width: "fit-content",
                        padding: "3px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PrimButton title="Change Image" />
                      <input
                        type="file"
                        style={{
                          position: " absolute",
                          filter: "alpha(opacity=0)",
                          top: "0",
                          right: "0",
                          align: "right",
                          minWidth: "100%",
                          minHeight: "100%",
                          fontSize: " 100px",
                          opacity: "0",
                          outline: "none",
                          background: "white",
                          cursor: "inherit",
                          display: "block",
                        }}
                        onChange={(event) => {
                          handleImageChange(event);
                        }}
                      />
                    </span>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7} md={8}>
              <Typography component="form" onSubmit={handleSubmit(updateInfo)}>
                <Box
                  sx={{
                    p: 2,
                    bgcolor: colors.white,
                    borderRadius: "16px",
                    boxShadow: colors.cardShadow,
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputField
                        label="Business Name"
                        defaultValue={userData.name}
                        register={register("name", {
                          required: "Please fill all fields",
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="Email"
                        defaultValue={userData.email}
                        register={register("email", {
                          required: "Please fill all fields",
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        label="Address"
                        defaultValue={userData.address}
                        register={register("address", {
                          required: "Please fill all fields",
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        label="Phone Number"
                        defaultValue={userData.phone}
                        register={register("phone", {
                          required: "Please fill all fields",
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        label="Website"
                        defaultValue={userData.website}
                        register={register("website", {
                          required: "Please fill all fields",
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>State</InputLabel>
                          <Select
                            defaultValue={userData.state}
                            label="State"
                            onChange={(item) => {
                              setSelectedState(item.target.value);
                            }}
                            sx={{
                              textAlign: "left",
                              // mb: 2,
                              borderRadius: 2,
                            }}
                            {...register("state", {
                              required: "Please fill all fields",
                              pattern: {
                                message: "Please fill all fields",
                              },
                            })}
                          >
                            {statesName.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12}>
                      <TextField
                        label="About"
                        defaultValue={userData.about}
                        multiline
                        rows={4}
                        fullWidth
                        InputProps={{
                          style: { borderRadius: 8 },
                        }}
                        {...register("about", {
                          required: "Please fill all fields",
                          pattern: {
                            message: "Please fill all fields",
                          },
                        })}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          width: "100%",
                          py: 1,
                        }}
                      >
                        <PrimButton type="submit" title="Save Changes" />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
              <Box
                sx={{
                  bgcolor: colors.white,
                  mt: 1,
                  p: 2,
                  borderRadius: "16px",
                  boxShadow: colors.cardShadow,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 700, mb: 2 }}>
                  Change Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit3(changePassword)}>
                  <Grid container spacing={1} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        label={"Old Password"}
                        type={showOldPassword ? "text" : "password"}
                        error={errors3?.oldPassword?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                                {showOldPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        register={register3("oldPassword", {
                          required: "Please enter old password.",
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6}>
                      <InputField
                        label={"New Password"}
                        type={showPassword ? "text" : "password"}
                        error={errors3?.newPassword?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        register={register3("newPassword", {
                          required: "Please enter new password.",
                          minLength: {
                            value: 6,
                            message: "Password must be have 6 contain characters",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        label={"Confirm Password"}
                        type={showConfirmPassword ? "text" : "password"}
                        error={errors3?.confirmPassword?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                {showConfirmPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        register={register3("confirmPassword", {
                          required: "Please enter confirm password",
                          validate: (value) => value === password.current || "Confirm password does not match",
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <PrimButton type="submit" title="Change Password" />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Fragment>
        </Grid>
      )}
    </Fragment>
  );
};

export default UserProfile;

import React from "react";
import { FormControl, TextField } from "@mui/material";
import { Error } from "../UI/Error";

const AuthField = (props) => {
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        borderRadius: 8,
      }}
    >
      <TextField
        {...props}
        variant="outlined"
        error={props.error && true}
        InputProps={{
          endAdornment: props.InputProps?.endAdornment,
          style: { borderRadius: 8 },
        }}
        {...props.register}
      />
      {props.error && <Error message={props.error} />}
    </FormControl>
  );
};

export default AuthField;

import React from "react";
import { FormControl, TextField } from "@mui/material";
import { Error } from "../../components/UI/Error";

const InputField = ({ value, label, error, register, type, readOnly,InputProps, defaultValue, disable, onChange }) => {
  return (
    <FormControl variant="standard" fullWidth sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}>
      <TextField
        disabled={disable}
        inputProps={{
          style: { borderRadius: 12 },
          readOnly: readOnly ? true : false,
        }}
        sx={{
          mb: error ? "0px" : 2,
        }}
        variant="outlined"
        label={label}
        defaultValue={defaultValue}
        value={value}
        type={type}
        error={error && true}
        InputProps={InputProps}
        onChange={(e) => onChange(e)}
        {...register}
      />
      {error && <Error message={error} />}
    </FormControl>
  );
};

export default InputField;

import { ChevronRightRounded, HomeTwoTone } from "@mui/icons-material";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import colors from "../../style/colors";

const useStyle = makeStyles({
  linkBtn: {
    color: colors.primary,
    textDecoration: "none",
    "&:hover": {
      color: colors.textSecondary,
      transition: "0.3s",
    },
  },
});

const BreadCrumbs = ({ currentPage }) => {
  const classes = useStyle();
  return (
    <Box role="presentation" sx={{ ml: { xs: 4, md: 0 } }}>
      <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightRounded fontSize="small" />} sx={{ fontSize: "12px" }}>
        <Link to="/" className={classes.linkBtn}>
          {/* <HomeTwoTone sx={{ fontSize: "18px", mt: 0.2 }} /> */}
          <Typography variant="body1" sx={{ fontSize: "12px" }} color={colors.textSecondary}>
            Dashboard
          </Typography>
        </Link>

        {currentPage.map((item, index) => (
          <Typography key={index} variant="body1" sx={{ fontSize: "12px" }} color={colors.textSecondary}>
            {item}
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadCrumbs;

import React, { Fragment, useState } from "react";
import Layout from "../../layout/Layout";
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography, tableCellClasses } from "@mui/material";
import colors from "../../style/colors";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import InputField from "../../components/TextField/InputField";
import { AddBox, Category, Delete, AddCircle, EditCalendar, EditNote, EditTwoTone } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import PrimaryTable from "../../components/Tables/PrimaryTable";
import Pagination from "../../components/Pagination/Pagination";
import { furnitureRows } from "../../utils/DummyData";
import AddCategoryDialog from "../../components/Dialog/CategoryDialog/AddCategoryDialog";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import EditCategoryDialog from "../../components/Dialog/CategoryDialog/EditCategoryDialog";
import CategoryServices from "../../apis/Category/CategoryServices";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/Toaster";
import { useEffect } from "react";
import { Loading } from "../../components/UI/Loader";

//* Data Table Styling
const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 700,
    border: 0,
    whiteSpace: "nowrap",
    backgroundColor: colors.bgColor,
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
  },
}));

const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

//* Logic for Pagination
// function usePagination(data, itemsPerPage) {
//   const [currentPage, setCurrentPage] = useState(1);
//   const maxPage = Math.ceil(data.length / itemsPerPage);

//   function currentData() {
//     const begin = (currentPage - 1) * itemsPerPage;
//     const end = begin + itemsPerPage;
//     return data.slice(begin, end);
//   }

//   function next() {
//     setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
//   }

//   function prev() {
//     setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
//   }

//   function jump(page) {
//     const pageNumber = Math.max(1, page);
//     setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
//   }

//   return { next, prev, jump, currentData, currentPage, maxPage };
// }
//* Table Header Array
const tableHeader = ["Id", "Category", "Action"];

const CategoryCreate = () => {
  const [categoryDialog, setCategoryDialog] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [catgName, setCatgName] = useState("");
  const [catgId, setCatgId] = useState("");
  const [loader, setLoader] = useState(true);

  // *For Pagination
  // eslint-disable-next-line no-unused-vars
  const [totalCount, setTotalCount] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);

  //* API FOR GET ALL CATEGORIES
  const getCategories = async () => {
    try {
      const { data } = await CategoryServices.getCategories();
      if (data === null) return;
      setCategoryData(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //* API FOR CREATE CATEGORY
  const createCategories = async (formData, reset) => {
    try {
      const obj = { name: formData.catgName };
      const { data } = await CategoryServices.createCategories(obj);
      if (data === null) return;
      SuccessToaster("Category Created");
      reset();
      setCategoryDialog(false);
      getCategories();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* API FOR DELETE CATEGORIES
  const deleteCategory = async () => {
    try {
      const { data } = await CategoryServices.deleteCategory(catgId);
      if (data === null) return;
      SuccessToaster("Category Deleted");
      getCategories();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const updateCategory = async (formData, reset) => {
    try {
      const obj = { name: formData.catgName };
      const { data } = await CategoryServices.updateCategories(catgId, obj);
      if (data === null) return;
      SuccessToaster("Category Updated");
      setCatgName("");
      setEditCategory(false);
      getCategories();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //* For Add Image
  // const createData = (formData) => {
  //   const data = [];
  //   data.push({ categories: formData.catgName });
  //   setCategory([...category, ...data]);
  //   setValue("catgName", "");
  // };
  //* For Delete Data
  // const deleteData = (value) => {
  //   console.log("🚀 ~ file: CategoryCreate.js:109 ~ deleteData ~ value:", value);
  //   const remove = category.filter((item) => {
  //     return item.categories !== value;
  //   });
  //   setCategory(remove);
  // };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <Fragment>
      <AddCategoryDialog open={categoryDialog} onClose={() => setCategoryDialog(false)} action={createCategories} />
      <EditCategoryDialog open={editCategory} onClose={() => setEditCategory(false)} action={updateCategory} name={catgName} />
      <ConfirmationDialog open={confirmDialog} onClose={() => setConfirmDialog(false)} action={deleteCategory} title="Delete this category?" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "16px",
          flexWrap: "wrap",
          bgcolor: colors.white,
          my: 1,
          p: 2,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AddBox sx={{ mr: 1 }} />
          <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: { xs: "14px", md: "18px" } }}>
            Categories
          </Typography>
        </Box>
        <BreadCrumbs currentPage={["Category", "Create Category"]} />
      </Box>
      {loader ? (
        <Box sx={{ display: "flex", justifyContent: "center", pl: 16, py: 4 }}>
          <Loading />
        </Box>
      ) : (
        <Fragment>
          <Grid container>
            <Grid item md={12} sx={{ display: "flex", justifyContent: "end", pt: 1 }}>
              <Button
                startIcon={<AddCircle />}
                onClick={() => setCategoryDialog(!categoryDialog)}
                sx={{
                  minWidth: 12,
                  fontSize: "12px",
                  textTransform: "none",
                  color: colors.white,
                  bgcolor: colors.secondary,
                  borderRadius: "8px",
                  "&:hover": {
                    bgcolor: colors.lightFont,
                    transition: "0.3s ease-in-out",
                  },
                }}
              >
                New Category
              </Button>
            </Grid>
          </Grid>

          <TableContainer
            component={Paper}
            sx={{
              mt: 2,
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.08)",
              borderRadius: 2,
              maxHeight: { xs: "auto", sm: "auto", md: "calc(100vh - 300px)" },
              maxWidth: { xs: "auto", sm: "auto" },
            }}
          >
            <Table stickyHeader sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  {tableHeader.map((item, index) => (
                    <Cell key={index}>{item}</Cell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryData.length > 0 ? (
                  <Fragment>
                    {categoryData.map((item, index) => (
                      <Row key={index} sx={{ bgcolor: index % 2 !== 0 && colors.hexGray }}>
                        <Cell>
                          <Typography variant="body1">{item.id}</Typography>
                        </Cell>

                        <Cell>
                          <Typography variant="body1">{item.name}</Typography>
                        </Cell>

                        {/* <Cell>
                          <Typography variant="body1">02/21/23</Typography>
                        </Cell> */}
                        <Cell>
                          <Box>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => {
                                  setEditCategory(!editCategory);
                                  setCatgName(item.name);
                                  setCatgId(item.id);
                                }}
                              >
                                <EditTwoTone sx={{ color: colors.primary }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Delete"
                              onClick={() => {
                                setConfirmDialog(!confirmDialog);
                                setCatgId(item.id);
                              }}
                            >
                              <IconButton>
                                <Delete sx={{ color: colors.opal }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Cell>
                      </Row>
                    ))}
                  </Fragment>
                ) : (
                  <Row>
                    <Cell colSpan={furnitureRows.length} align="center" sx={{ fontWeight: 600 }}>
                      No Data Found
                    </Cell>
                  </Row>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ========== Pagination ========== */}
          <Pagination
            currentPage={currentPage}
            pageSize={10}
            // onPageSizeChange={changePageSize}
            tableCount={categoryData.length}
            totalCount={categoryData.length}
            // onPageChange={(page) => changePage(page)}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default CategoryCreate;

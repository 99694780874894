import React, { Fragment, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// import { ImageAvatar } from "../avatar/ImageAvatar";
import colors from "../../style/colors";
import styled from "@emotion/styled";
// import { ErrorToaster } from "../toaster/Toaster";
// import Pagination from "../pagination/Pagination";
// import { ActionButton } from "../buttons/Buttons";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit, EditTwoTone, Visibility } from "@mui/icons-material";

import Pagination from "../Pagination/Pagination";
import OrderStatusDialog from "../Dialog/OrderStatusDialog";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import { Loading } from "../UI/Loader";
import OrdersServices from "../../apis/Orders/OrdersServices";
import { ErrorToaster, SuccessToaster } from "../Toaster/Toaster";
import moment from "moment/moment";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 700,
    border: 0,
    whiteSpace: "nowrap",
    backgroundColor: colors.bgColor,
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    minWidth: 145,
    fontSize: 14,
    border: 0,
  },
}));

function PrimaryTable(props) {
  const { getOrders, disableClick, tableHead, tableData, balance, paid, footerTotal, totalCount, changePage, currentPage, loading, filterObj } = props;

  const navigate = useNavigate();
  // For Order Status Dialog
  const [statusDialog, setStatusDialog] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [markPaid, setMarkPaid] = useState("");

  const updateStatus = async (status, mark) => {
    try {
      let obj = { status: status, mark_as_paid: mark ? 1 : 0 };
      const { data } = await OrdersServices.orderStatus(orderId, obj);
      if (data === null) return;
      SuccessToaster("Order status updated");
      setStatusDialog(false);
      getOrders();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const deleteOrder = async () => {
    try {
      const { data, message } = await OrdersServices.deleteOrders(orderId);
      if (data === null) return;
      SuccessToaster(message);
      getOrders();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Fragment>
      <OrderStatusDialog open={statusDialog} onClose={() => setStatusDialog(false)} status={orderStatus} action={updateStatus} paid={markPaid} />
      <ConfirmationDialog open={confirmDialog} onClose={() => setConfirmDialog(false)} action={deleteOrder} title="Delete this order?" />
      {/* ========== Table ========== */}

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", pl: 16, py: 4 }}>
          <Loading />
        </Box>
      ) : (
        <Fragment>
          <TableContainer
            component={Paper}
            sx={{
              mt: 2,
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.08)",
              borderRadius: 2,
              maxHeight: { xs: "auto", sm: "auto", md: "calc(100vh - 300px)" },
              maxWidth: { xs: "auto", sm: "auto" },
              "&::-webkit-scrollbar": {
                bgcolor: colors.white,
                height: "6px",
                borderRadius: "12px",
                cursor: "pointer",
              },
              "&::-webkit-scrollbar-thumb": {
                bgcolor: colors.primary,
                borderRadius: "14px",
                cursor: "pointer",
              },
            }}
          >
            <Table stickyHeader sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  {tableHead.map((item, index) => (
                    <Cell key={index}>{item}</Cell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  <Fragment>
                    {tableData.map((item, index) => (
                      <Row key={index} sx={{ cursor: disableClick ? 'inherit' : "pointer", bgcolor: index % 2 !== 0 && colors.hexGray }} onClick={() => disableClick ? '' : navigate(`/orderdetail/${item?.id}`)}>
                        {props.type === "order" ? (
                          <TableCell sx={{ fontSize: 14, border: 0 }}>
                            <Typography variant="body1">{item.id}</Typography>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <Cell>
                          <Typography variant="body1">{item.order_no}</Typography>
                        </Cell>
                        <Cell>
                          <Typography sx={{ fontSize: "14px" }}>
                            <b>Name: </b>
                            {item?.client_name ?? "--"}
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            <b>Phone: </b>
                            {item.client_phone}
                          </Typography>
                        </Cell>
                        {/* <Cell>
                      <Typography variant="body1">{item.phone}</Typography>
                    </Cell> */}
                        <Cell>
                          <Typography variant="body1">{moment(item.order_date).format("DD-MM-YYYY")}</Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{moment(item.delivery_date).format("DD-MM-YYYY")}</Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{item.total} AED </Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{item.advance_in_paid} AED </Typography>
                        </Cell>
                        <Cell>
                          <Typography variant="body1">{item.balance} AED </Typography>
                        </Cell>
                        {props.type === "order" ? (
                          <Cell>
                            {item.status === "Delivered" ? (
                              <Typography
                                onClick={() => {
                                  setStatusDialog(!statusDialog);
                                  setOrderStatus(item?.status);
                                  setMarkPaid(item?.mark_as_paid);
                                  setOrderId(item?.id);
                                }}
                                bgcolor={colors.completeBg}
                                color={colors.ufoGreen}
                                sx={{
                                  cursor: "pointer",
                                  py: 0.5,
                                  borderRadius: "12px",
                                  width: "90px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  "&:hover": {
                                    transition: "0.3s ease-in-out",
                                    bgcolor: colors.iconBg,
                                  },
                                }}
                              >
                                {item.status}
                              </Typography>
                            ) : item.status === "Pending" ? (
                              <Typography
                                onClick={() => {
                                  setStatusDialog(!statusDialog);
                                  setOrderStatus(item?.status);
                                  setMarkPaid(item?.mark_as_paid);
                                  setOrderId(item?.id);
                                }}
                                bgcolor={colors.pendingBg}
                                color={colors.pending}
                                sx={{
                                  cursor: "pointer",
                                  py: 0.5,
                                  borderRadius: "12px",
                                  width: "90px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  "&:hover": {
                                    transition: "0.3s ease-in-out",
                                    bgcolor: colors.orangeBg,
                                  },
                                }}
                              >
                                {item.status}
                              </Typography>
                            ) : (
                              <Typography
                                onClick={() => {
                                  setStatusDialog(!statusDialog);
                                  setOrderStatus(item?.status);
                                  setMarkPaid(item?.mark_as_paid);
                                  setOrderId(item?.id);
                                }}
                                bgcolor={colors.offline}
                                color={colors.redicalRed}
                                sx={{
                                  cursor: "pointer",
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: "12px",
                                  width: "90px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  "&:hover": {
                                    transition: "0.3s ease-in-out",
                                    bgcolor: colors.opalBg,
                                  },
                                }}
                              >
                                {item.status}
                              </Typography>
                            )}
                          </Cell>
                        ) : (
                          <Cell>
                            {item.status === "Delivered" ? (
                              <Typography
                                onClick={() => {
                                  // setStatusDialog(!statusDialog);
                                  // setOrderStatus(item?.status);
                                  setOrderId(item?.id);
                                }}
                                bgcolor={colors.completeBg}
                                color={colors.ufoGreen}
                                sx={{
                                  cursor: "pointer",
                                  py: 0.5,
                                  borderRadius: "12px",
                                  width: "90px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  "&:hover": {
                                    transition: "0.3s ease-in-out",
                                    bgcolor: colors.completeBg,
                                  },
                                }}
                              >
                                {item.status}
                              </Typography>
                            ) : item.status === "Pending" ? (
                              <Typography
                                onClick={() => {
                                  // setStatusDialog(!statusDialog);
                                  // setOrderStatus(item?.status);
                                  setOrderId(item?.id);
                                }}
                                bgcolor={colors.pendingBg}
                                color={colors.pending}
                                sx={{
                                  cursor: "pointer",
                                  py: 0.5,
                                  borderRadius: "12px",
                                  width: "90px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  "&:hover": {
                                    transition: "0.3s ease-in-out",
                                    bgcolor: colors.pendingBg,
                                  },
                                }}
                              >
                                {item.status}
                              </Typography>
                            ) : (
                              <Typography
                                onClick={() => {
                                  // setStatusDialog(!statusDialog);
                                  // setOrderStatus(item?.status);
                                  setOrderId(item?.id);
                                }}
                                bgcolor={colors.offline}
                                color={colors.redicalRed}
                                sx={{
                                  cursor: "pointer",
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: "12px",
                                  width: "90px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  "&:hover": {
                                    transition: "0.3s ease-in-out",
                                    bgcolor: colors.offline,
                                  },
                                }}
                              >
                                {item.status}
                              </Typography>
                            )}
                          </Cell>
                        )}
                        {props.type === "order" ? (
                          <Cell>
                            <Box display="flex">
                              <Tooltip title="View">
                                <IconButton onClick={() => navigate(`/orderdetail/${item.id}`)}>
                                  <Visibility sx={{ fontSize: "20px", color: colors.primBlue }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit">
                                <IconButton onClick={() => navigate(`/updateorder/${item.id}`, { state: { data: item } })}>
                                  <EditTwoTone sx={{ fontSize: "20px", color: colors.primary }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setConfirmDialog(true);
                                    setOrderId(item.id);
                                  }}
                                >
                                  <DeleteIcon sx={{ fontSize: "20px", color: colors.opal }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Cell>
                        ) : (
                          ""
                        )}
                      </Row>
                    ))}
                  </Fragment>
                ) : (
                  <Row>
                    <Cell colSpan={tableHead.length} align="center" sx={{ fontWeight: 600 }}>
                      No Data Available
                    </Cell>
                  </Row>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {props.type !== "order" && (
            <Grid container spacing={6}>
              <Grid item sm={4} md={5}>
                {/* <TableFooter>Table Footer Here</TableFooter> */}
              </Grid>
              <Grid item sm={4} md={7}>
                <TableFooter>
                  <Row>
                    <Cell sx={{ width: "90px", fontSize: "16px", fontWeight: "bold" }}>Total</Cell>
                    <Cell sx={{ width: "140px", fontSize: "16px", fontWeight: "bold" }}>{footerTotal.length ? footerTotal : 0} AED</Cell>
                    <Cell sx={{ width: "140px", fontSize: "16px", fontWeight: "bold" }}>{paid.length ? paid : 0} AED</Cell>
                    <Cell sx={{ width: "140px", fontSize: "16px", fontWeight: "bold" }}>{balance.length ? balance : 0} AED</Cell>
                  </Row>
                </TableFooter>
              </Grid>
            </Grid>
          )}

          {/* ========== Pagination ========== */}
          <Pagination
            currentPage={currentPage}
            pageSize={10}
            filterObj={filterObj}
            // onPageSizeChange={changePageSize}
            tableCount={tableData.length}
            totalCount={totalCount}
            onPageChange={(page, orderFrom) => changePage(page, orderFrom)}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default PrimaryTable;

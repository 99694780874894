import React from "react";
import { Box, Dialog, IconButton, DialogContent, Typography, Button, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../../style/colors";
import InputField from "../../TextField/InputField";
import { useForm } from "react-hook-form";

const AddCategoryDialog = ({ open, onClose, action }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "500px",
          maxWidth: "500px",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" sx={{ backgroundImage: colors.top }}>
        <Box py={2} px={4}>
          <Typography variant="h6" fontWeight="bold">
            Add Category
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ "&:hover": { backgroundColor: colors.top } }}>
          <CancelIcon
            sx={{
              fontSize: "26px",
              color: colors.secondary,
              mr: 1,
              "&:hover": { color: colors.lightFont },
            }}
          />
        </IconButton>
      </Box>
      <DialogContent sx={{ pt: 2 }}>
        <Typography component="form" onSubmit={handleSubmit((formData) => action(formData, reset))}>
          <Grid container spacing={1} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <InputField
                label="Category Name"
                error={errors?.catgName?.message}
                register={register("catgName", {
                  required: "Please enter category name",
                })}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
            <Button
              type="submit"
              // onClick={() => {
              //   action(onClose);
              // }}
              sx={{
                minWidth: 120,
                fontSize: "14px",
                textTransform: "none",
                color: colors.white,
                bgcolor: colors.primary,
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: colors.lightFont,
                  transition: "0.3s ease-in-out",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AddCategoryDialog;

import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { Box } from "@mui/material";
import colors from "../../style/colors";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monthly Orders Sale",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Delivered Order",
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: colors.primary,
//       backgroundColor: colors.active,
//     },
//     {
//       label: "In-Progress Orders",
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: colors.redicalRed,
//       backgroundColor: colors.opal,
//     },
//     {
//       label: "Pending Orders",
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: colors.pending,
//       backgroundColor: colors.pendingBg,
//     },
//   ],
// };

const LineChart = ({ chartData }) => {
  const pendingData = Array.from({ length: 12 }, () => 0);
  const inprogressData = Array.from({ length: 12 }, () => 0);
  const deliveredData = Array.from({ length: 12 }, () => 0);

  if (chartData) {
    for (const status of ["pending", "inprogress", "delivered"]) {
      for (const { total, month } of Object?.values(chartData[status])) {
        const index = labels.indexOf(month);
        if (index !== -1) {
          if (status === "pending") {
            pendingData[index] += total;
          } else if (status === "inprogress") {
            inprogressData[index] += total;
          } else if (status === "delivered") {
            deliveredData[index] += total;
          }
        }
      }
    }
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Delivered Amount",
        data: deliveredData,
        borderColor: colors.primary,
        backgroundColor: colors.active,
      },
      {
        label: "In-Progress Amount",
        data: inprogressData,
        borderColor: colors.redicalRed,
        backgroundColor: colors.opal,
      },
      {
        label: "Pending Amount",
        data: pendingData,
        borderColor: colors.pending,
        backgroundColor: colors.pendingBg,
      },
    ],
  };

  return (
    <Box
      sx={{
        height: { xs: 200, sm: 340, md: 380 },
        bgcolor: colors.white,
        borderRadius: "16px",
        boxShadow: colors.cardShadow,
      }}
    >
      <Line options={options} data={data} />
    </Box>
  );
};

export default LineChart;

import { get, post, patch, deleted } from "../index";
import Orders from "./Orders.Routes";

const OrdersServices = {
  getOrders: async (param) => {
    const data = await get(Orders.getOrders, param);
    return data;
  },
  getFliterOrders: async (param) => {
    const data = await get(Orders.getFilterOrders, param);
    return data;
  },
  createOrders: async (obj) => {
    const data = await post(Orders.createOrder, obj);
    return data;
  },
  updateOrders: async (id, obj) => {
    const data = await post(Orders.updateOrder + `${id}`, obj);
    return data;
  },
  deleteOrders: async (id) => {
    const data = await deleted(Orders.deleteOrder + `${id}`);
    return data;
  },
  imageOrder: async (obj) => {
    const data = await post(Orders.imageOrder, obj);
    return data;
  },
  orderDetail: async (id) => {
    const data = await get(Orders.orderDetail + `${id}`);
    return data;
  },
  orderNo: async () => {
    const data = await get(Orders.orderNo);
    return data;
  },
  orderStatus: async (id, obj) => {
    const data = await post(Orders.orderStatus + `${id}`, obj);
    return data;
  },
};

export default OrdersServices;
